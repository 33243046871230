import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AgritourismService } from '../../../services/agritourism.service';

@Component({
  selector: 'curbnturf-agritourism-day-one',
  templateUrl: './day-one.component.html',
  styleUrls: ['./day-one.component.scss'],
})
export class AgritourismDayOneComponent {
  constructor(private agritourismService: AgritourismService, private router: Router) {}

  complete() {
    this.agritourismService.complete(1).subscribe(() => {
      this.router.navigate(['', 'course', 'day-2']);
    });
  }
}
