import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AWS_REGION, COGNITO_CLIENT_ID, COGNITO_USER_POOL_ID, IUser } from '@curbnturf/entities';
import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  ICognitoUserSessionData,
  ISignUpResult,
} from 'amazon-cognito-identity-js';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const requestUrl = `https://cognito-idp.${AWS_REGION}.amazonaws.com`;

@Injectable()
export class AgritourismCognitoService {
  pool_region = AWS_REGION;

  userPool: CognitoUserPool = new CognitoUserPool({
    UserPoolId: COGNITO_USER_POOL_ID,
    ClientId: COGNITO_CLIENT_ID,
  });

  authUser?: CognitoUser;

  constructor(private httpClient: HttpClient) {}

  public signUp(email: string, password: string): Promise<ISignUpResult | undefined> {
    return new Promise((resolve, reject) => {
      this.userPool.signUp(
        email.toLocaleLowerCase(),
        password,
        [],
        [],
        (err, result) => {
          if (err) {
            return reject(err);
          }

          return resolve(result);
        },
        { agritourism: 'SignUp' },
      );
    });
  }

  public authenticateUser(email: string, password: string): Promise<IUser> {
    return new Promise((resolve, reject) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: email.toLocaleLowerCase(),
        Password: password,
      });

      const userData = {
        Username: email.toLocaleLowerCase(),
        Pool: this.userPool,
      };

      this.authUser = new CognitoUser(userData);
      this.authUser.authenticateUser(authenticationDetails, {
        onSuccess: (result: CognitoUserSession): void => {
          resolve(this.sessionToUser(result));
        },
        onFailure: (err: Error): void => {
          reject(err);
        },
        newPasswordRequired: (userAttributes, requiredAttributes): void => {
          resolve({ ...userAttributes, temporaryPassword: true });
        },
      });
    });
  }

  public newPassword(password: string): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!this.authUser) {
        reject('Must be authenticated to update password');
      } else {
        this.authUser.completeNewPasswordChallenge(
          password,
          {},
          {
            onSuccess: (result: CognitoUserSession): void => {
              resolve('Done');
            },
            onFailure: (err: Error): void => {
              reject(err);
            },
          },
        );
      }
    });
  }

  public renew(renewToken: string, idToken: string): Observable<any> {
    const requestPayload = {
      AuthFlow: 'REFRESH_TOKEN_AUTH',
      AuthParameters: {
        REFRESH_TOKEN: renewToken,
      },
      ClientId: COGNITO_CLIENT_ID,
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-amz-json-1.1',
      'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
    });

    return this.httpClient
      .post(requestUrl, requestPayload, {
        headers,
      })
      .pipe(
        map((result: any) => {
          const session = this.sessionFromData({
            ...result.AuthenticationResult,
            RefreshToken: renewToken,
          });

          if (!session || !session.getIdToken() || !session.getIdToken().getJwtToken()) {
            throw new Error('Unable to get session data from authentication service.');
          }

          const idPayload = new CognitoIdToken({
            IdToken: session.getIdToken().getJwtToken(),
          }).decodePayload();

          if (!idPayload || !idPayload['email']) {
            throw new Error('Received invalid session data from authentication service.');
          }

          const userData = {
            Username: idPayload['email'].toLocaleLowerCase(),
            Pool: this.userPool,
          };

          this.authUser = new CognitoUser(userData);

          this.authUser.setSignInUserSession(session);
          return this.sessionToUser(session);
        }),
      );
  }

  private sessionFromData(result: { IdToken: string; AccessToken: string; RefreshToken: string }): CognitoUserSession {
    const token: ICognitoUserSessionData = {
      IdToken: new CognitoIdToken({ IdToken: result.IdToken }),
      AccessToken: new CognitoAccessToken({ AccessToken: result.AccessToken }),
      RefreshToken: new CognitoRefreshToken({
        RefreshToken: result.RefreshToken,
      }),
    };

    const session = new CognitoUserSession(token);

    return session;
  }

  private sessionToUser(result: CognitoUserSession): IUser {
    const payload = result.getIdToken().decodePayload();

    return {
      authAccessToken: result.getAccessToken().getJwtToken(),
      authIdToken: result.getIdToken().getJwtToken(),
      authRenewToken: result.getRefreshToken().getToken(),
      authExpires: result.getIdToken().getExpiration() * 1000,
      email: payload['email'].toLocaleLowerCase(),
      firstName: payload['given_name'],
      lastName: payload['family_name'],
    };
  }
}
