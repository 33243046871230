<div class="content-container">
  <div class="card-content-outer nandor">
    <div class="overflow-hidden">
      <h1 class="banner-title">Day 5: Hosting Guests on Your Property.</h1>
      <p>
        By offering your home or other places on your property, you can host guests to encourage longer stay periods and
        create more value. However, if you are not comfortable with Airbnb hosting or don&#x27;t want the maintenance or
        risk, there is an additional way to generate income from your property through CurbNTurf.
      </p>
      <p>
        CurbNTurf is a listing place for RVers who are looking for a place to park. Property owners and agritourism
        businesses can list their property on CurbNTurf and rent it out to RVers for a fee.
      </p>
      <p>
        In today&#x27;s video, we will discuss how you can capitalize on this opportunity and use your property to
        generate even more income. We&#x27;ll also look into the process of setting up the listing on CurbNTurf and what
        you need to know before hosting RVer guests on your property.
      </p>
      <div class="challenge-emphasis">Jump into DAY 5 COURSE below:<br /></div>
      <div class="challenge-course-vid">
        <div class="w-video w-embed">
          <vg-player>
            <vg-overlay-play></vg-overlay-play>
            <vg-buffering></vg-buffering>
            <vg-controls>
              <vg-play-pause></vg-play-pause>
              <vg-playback-button></vg-playback-button>
              <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
              <vg-scrub-bar>
                <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
              </vg-scrub-bar>
              <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
              <vg-mute></vg-mute>
              <vg-volume></vg-volume>
              <vg-fullscreen></vg-fullscreen>
            </vg-controls>

            <video [vgMedia]="$any(media)" #media id="singleVideo" preload="auto" crossorigin>
              <source src="/videos/acc-day-5.webm" type='video/webm; codecs="vp8, vorbis"' />
              <source src="/videos/acc-day-5.mp4" type='video/mp4; codecs="avc1.4d002a"' />
            </video>
          </vg-player>
        </div>
      </div>
      <div class="course-control-wrapper">
        <a href="../documents/Day-5---Opportunities-with-CurbNTurf-SM.pdf" class="attachment-pdf w-inline-block">
          <div class="copy-image">Click Here To Download Day 5 Handout (pdf)</div>
        </a>
        <a (click)="complete()" class="button-primary w-inline-block">
          <div class="button-content">
            <div class="button-color">Mark as Complete</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
