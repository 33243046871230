import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { IUser } from '@curbnturf/entities';
import { ModalController } from '@ionic/angular';
import { AgritourismAuthModalComponent } from '../../components/auth/auth-modal/auth-modal.component';
import { AgritourismLocalStorageService } from '../../services/local-storage.service';

@Component({
  selector: 'agritourism-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss'],
})
export class AgritourismCoursesComponent implements OnInit {
  user?: IUser;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private hostEl: ElementRef,
    private localStorageService: AgritourismLocalStorageService,
    private modalController: ModalController,
  ) {}

  ngOnInit() {
    this.user = this.localStorageService.user;

    if (this.document) {
      // jQuery
      const jQScriptTag = this.document.createElement('script');
      jQScriptTag.type = 'text/javascript';
      jQScriptTag.src = `https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=5fbe83fe8b482568c9db3c4f`;
      this.hostEl.nativeElement.appendChild(jQScriptTag);

      // webflow
      const wfScriptTag = this.document.createElement('script');
      wfScriptTag.type = 'text/javascript';
      wfScriptTag.src = `/js/splash.js`;
      this.hostEl.nativeElement.appendChild(wfScriptTag);
    }
  }

  async signUpModal() {
    const modal = await this.modalController.create({
      component: AgritourismAuthModalComponent,
      cssClass: 'auth-modal agritourism',
    });

    await modal.present();
  }
}
