import { Component, Input } from '@angular/core';

@Component({
  selector: 'agritourism-course-course-status-prompt',
  templateUrl: './course-status-prompt.component.html',
  styleUrls: ['./course-status-prompt.component.scss'],
})
export class AgritourismCourseStatusPromptComponent {
  @Input() completed: boolean;
}
