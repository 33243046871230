import { isPlatformServer } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PATHS } from '@curbnturf/entities';
import { first } from 'rxjs/operators';
import { AgritourismAuthService } from '../../../auth/auth.service';
import { AgritourismAlertService } from '../../../services/alert.service';
import { AgritourismLocalStorageService } from '../../../services/local-storage.service';

@Component({
  selector: 'curbnturf-agritourism-auth-redirect',
  templateUrl: './auth-redirect.component.html',
  styleUrls: ['./auth-redirect.component.scss'],
})
export class AgritourismAuthRedirectComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AgritourismAlertService,
    private authService: AgritourismAuthService,
    private localStorageService: AgritourismLocalStorageService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {}

  ngOnInit() {
    if (!isPlatformServer(this.platformId)) {
      this.route.queryParamMap.pipe(first()).subscribe((params) => {
        if (params.keys.length > 0) {
          let confirmationCode = params.get('confirmationcode');
          // not sure why it respects capitalization sometimes and not others
          if (!confirmationCode) {
            confirmationCode = params.get('confirmationCode');
          }

          const username = params.get('username');
          if (confirmationCode && username) {
            return this.authService
              .confirmEmail(confirmationCode, username)
              .pipe(first())
              .subscribe((res) => {
                this.router.navigate([PATHS.login]);
                setTimeout(() => {
                  if (res) {
                    this.alertService.alert(
                      'success',
                      'You have successfully verified your email address.',
                      'Email address verified',
                    );
                  } else {
                    this.alertService.alert(
                      'warning',
                      'We were unable to verify your email address. Please try again!',
                      'Unable to verify',
                    );
                  }
                });
              });
          }
        }

        const redirectUrl = this.localStorageService.redirectUrl;
        if (redirectUrl) {
          this.localStorageService.redirectUrl = '';
          this.router.navigateByUrl(redirectUrl);
        } else {
          this.router.navigate(['', 'course']);
        }

        return;
      });
    }
  }
}
