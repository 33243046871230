import * as helpers from '@curbnturf/helpers';
import { toPrecision } from "@curbnturf/helpers";
import {
  ActivityLocation,
  FlowStepType,
  IActivityLocationSearchRequest,
  ILatLon,
  IMapPoint,
  ISignUpFlowStep,
  ISite,
  ISiteSearch,
  ISubPhoto,
  IUser,
} from '@curbnturf/objects';
import {
  DEFAULT_IMAGE_4X3_URL,
  DEFAULT_IMAGE_FLAT_URL,
  DEFAULT_IMAGE_MOTORHOME_URL,
  DEFAULT_IMAGE_TITLE_URL,
  DEFAULT_IMAGE_URL,
  DEFAULT_MAP_LATITUDE,
  DEFAULT_MAP_LONGITUDE,
  GUEST_FLOW_RV,
  GUEST_FLOW_TENT,
  HOST_BOONDOCK_FLOW,
  HOST_SITE_FLOW,
  IMAGE_URL
} from "./constants";
import { IPoiFilterPanelOptions } from './interfaces';
import { ICancellableDebounceObject } from './interfaces/cancellable-debounce-object';

const urlCleanRegex = /[\W_]+/g;

export namespace HelperFunctions {
  export const getPhotoUrl = (photo: ISubPhoto) => {
    if (photo && photo.key) {
      return helpers.getPhotoUrl(IMAGE_URL, photo);
    }
    return DEFAULT_IMAGE_URL;
  };

  export const getPhotoUrl3x4Large = (photo: ISubPhoto) => {
    if (photo && photo.key) {
      return helpers.getPhotoUrl3x4Large(IMAGE_URL, photo);
    }
    return DEFAULT_IMAGE_4X3_URL;
  };

  export const getPhotoUrl3x4Medium = (photo: ISubPhoto) => {
    if (photo && photo.key) {
      return helpers.getPhotoUrl3x4Medium(IMAGE_URL, photo);
    } else {
      return DEFAULT_IMAGE_4X3_URL;
    }
  };

  export const getPhotoUrl3x4Small = (photo: ISubPhoto) => {
    if (photo && photo.key) {
      return helpers.getPhotoUrl3x4Small(IMAGE_URL, photo);
    }
    return DEFAULT_IMAGE_4X3_URL;
  };

  export const getPhotoUrl3x4Thumbnail = (photo: ISubPhoto) => {
    if (photo && photo.key) {
      return helpers.getPhotoUrl3x4Thumbnail(IMAGE_URL, photo);
    }
    return DEFAULT_IMAGE_4X3_URL;
  };

  export const getPhotoUrlMedium = (photo: ISubPhoto) => {
    if (photo && photo.key) {
      return helpers.getPhotoUrlMedium(IMAGE_URL, photo);
    }
    return DEFAULT_IMAGE_URL;
  };

  export const getPhotoUrlFlatMedium = (photo: ISubPhoto) => {
    if (photo && photo.key) {
      return helpers.getPhotoUrlFlatMedium(IMAGE_URL, photo);
    }
    return DEFAULT_IMAGE_FLAT_URL;
  };

  export const getPhotoUrlFlatSmall = (photo: ISubPhoto) => {
    if (photo && photo.key) {
      return helpers.getPhotoUrlFlatSmall(IMAGE_URL, photo);
    }
    return DEFAULT_IMAGE_FLAT_URL;
  };

  export const getPhotoUrlFlatThumbnail = (photo: ISubPhoto) => {
    if (photo && photo.key) {
      return helpers.getPhotoUrlFlatThumbnail(IMAGE_URL, photo);
    }
    return DEFAULT_IMAGE_FLAT_URL;
  };

  export const getPhotoUrlSquareMedium = (photo: ISubPhoto) => {
    if (photo && photo.key) {
      return helpers.getPhotoUrlSquareMedium(IMAGE_URL, photo);
    }
    return DEFAULT_IMAGE_MOTORHOME_URL;
  };

  export const getPhotoUrlSquareSmall = (photo: ISubPhoto) => {
    if (photo && photo.key) {
      return helpers.getPhotoUrlSquareSmall(IMAGE_URL, photo);
    }
    return DEFAULT_IMAGE_URL;
  };

  export const getPhotoUrlSquareThumbnail = (photo: ISubPhoto) => {
    if (photo && photo.key) {
      return helpers.getPhotoUrlSquareThumbnail(IMAGE_URL, photo);
    }
    return DEFAULT_IMAGE_URL;
  };

  export const getPhotoUrlSquareTiny = (photo: ISubPhoto) => {
    if (photo && photo.key) {
      return helpers.getPhotoUrlSquareTiny(IMAGE_URL, photo);
    }
    return DEFAULT_IMAGE_URL;
  };

  export const getPhotoUrlTitleCropped = (photo: ISubPhoto) => {
    if (photo && photo.key) {
      return helpers.getPhotoUrlTitleCropped(IMAGE_URL, photo);
    }
    return DEFAULT_IMAGE_TITLE_URL;
  };

  export const getPhotoUrlOriginal = (photo: ISubPhoto) => {
    if (photo && photo.key) {
      return helpers.getPhotoUrlOriginal(IMAGE_URL, photo);
    }
    return DEFAULT_IMAGE_URL;
  };

  export const getDefaultLocation = (): ILatLon => {
    return { lat: DEFAULT_MAP_LATITUDE, lon: DEFAULT_MAP_LONGITUDE };
  };

  export const locationIsDefault = (location: ILatLon): boolean => {
    return location.lat === getDefaultLocation().lat && location.lon === getDefaultLocation().lon;
  };

  export const buildSiteRoute = (site: ISiteSearch | ISite | IMapPoint): string[] => {
    const route: string[] = [''];
    if (site.type === 'standard') {
      route.push('rv-camping');
    } else {
      route.push('boondocking');
    }
    if ('location' in site && site.location) {
      route.push(encodeURIComponent(site.location?.replace(urlCleanRegex, '_')));
    }
    if ('address' in site && site.address) {
      if (
        site.address.city &&
        site.address.city.trim().length > 0 &&
        site.address.state &&
        site.address.state.trim().length
      ) {
        route.push(
          encodeURIComponent(
            site.address.city?.replace(urlCleanRegex, '-') + '_' + site.address.state?.replace(urlCleanRegex, '-'),
          ),
        );
      }
    }
    if (site.name) {
      route.push(encodeURIComponent(site.name?.replace(urlCleanRegex, '-')));
    }
    if (site.id) {
      route.push(site.id.toString());
    }
    if ((site as ISite).syncId && !site.id) {
      route.push((site as ISite).syncId as string);
    }
    return route;
  };

  export const buildActivityRoute = (poi: ActivityLocation): string[] => {
    const route: string[] = [''];

    if (!poi) {
      return route;
    }

    if (poi.categoryDump) {
      route.push('rv-dump-site');
    } else if (poi.categoryRestArea) {
      route.push('rest-area');
    } else if (poi.categoryWifi) {
      route.push('wifi');
    } else if (poi.categoryPotableWater) {
      route.push('fresh-water');
    } else if (poi.categoryPropane) {
      route.push('propane');
    } else if (poi.activityBiking) {
      route.push('biking');
    } else if (poi.activityBoating) {
      route.push('boating');
    } else if (poi.activityFishing) {
      route.push('fishing');
    } else if (poi.activityGolf) {
      route.push('golf');
    } else if (poi.activityHiking) {
      route.push('hiking');
    } else if (poi.activityHorseback) {
      route.push('horseback-riding');
    } else if (poi.activityHunting) {
      route.push('hunting');
    } else if (poi.activityKayaking) {
      route.push('kayaking');
    } else if (poi.activityMotorSports) {
      route.push('motor-sports');
    } else if (poi.activityOhv) {
      route.push('ohv-trails');
    } else if (poi.activityRafting) {
      route.push('rafting');
    } else if (poi.activityRockClimbing) {
      route.push('rock-climbing');
    } else if (poi.activitySnowSports) {
      route.push('snow-sports');
    } else if (poi.activitySurfing) {
      route.push('surfing');
    } else if (poi.activitySwimming) {
      route.push('swimming');
    } else if (poi.activityWaterSports) {
      route.push('water-sports');
    } else if (poi.activityWildlifeWatching) {
      route.push('wildlife-watching');
    } else if (poi.activityWindSports) {
      route.push('wind-sports');
    } else {
      route.push('activity');
    }

    if (poi.address?.city && poi.address?.state) {
      route.push(
        encodeURIComponent(
          poi.address.city.replace(urlCleanRegex, '-') + '_' + poi.address.state.replace(urlCleanRegex, '-'),
        ),
      );
    }

    route.push(encodeURIComponent(poi.getName().replace(urlCleanRegex, '-')));

    if (poi.id) {
      route.push(poi.id.toString());
    }

    return route;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
    let timeout: ReturnType<typeof setTimeout>;

    return (...args: Parameters<F>): Promise<ReturnType<F>> =>
      new Promise((resolve) => {
        if (timeout) {
          clearTimeout(timeout);
        }

        timeout = setTimeout(() => resolve(func(...args)), waitFor);
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export const debounceCancellable = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
    const obj: ICancellableDebounceObject = {};

    return (...args: Parameters<F>): ICancellableDebounceObject => {
      if (obj.timeout) {
        clearTimeout(obj.timeout);
      }

      obj.timeout = setTimeout(() => func(...args), waitFor);

      return obj;
    };
  };

  export function poiSearchRequestToPoiFilterOptions(
    searchRequest: IActivityLocationSearchRequest,
  ): IPoiFilterPanelOptions {
    const custom =
      searchRequest.activities
        ?.filter((activity) => {
          return (
            activity !== 'biking' &&
            activity !== 'boating' &&
            activity !== 'fishing' &&
            activity !== 'golf' &&
            activity !== 'hiking' &&
            activity !== 'horseback' &&
            activity !== 'hunting' &&
            activity !== 'kayaking' &&
            activity !== 'motorSports' &&
            activity !== 'ohv' &&
            activity !== 'rafting' &&
            activity !== 'rockClimbing' &&
            activity !== 'snowSports' &&
            activity !== 'surfing' &&
            activity !== 'swimming' &&
            activity !== 'waterSports' &&
            activity !== 'wildlifeWatching' &&
            activity !== 'windSports'
          );
        })
        .join(',') || '';

    return {
      activity: searchRequest.selected?.activity || false,
      activities: {
        biking: searchRequest.activities?.includes('biking') || false,
        boating: searchRequest.activities?.includes('boating') || false,
        fishing: searchRequest.activities?.includes('fishing') || false,
        golf: searchRequest.activities?.includes('golf') || false,
        hiking: searchRequest.activities?.includes('hiking') || false,
        horseback: searchRequest.activities?.includes('horseback') || false,
        hunting: searchRequest.activities?.includes('hunting') || false,
        kayaking: searchRequest.activities?.includes('kayaking') || false,
        motorSports: searchRequest.activities?.includes('motorSports') || false,
        ohv: searchRequest.activities?.includes('ohv') || false,
        rafting: searchRequest.activities?.includes('rafting') || false,
        rockClimbing: searchRequest.activities?.includes('rockClimbing') || false,
        snowSports: searchRequest.activities?.includes('snowSports') || false,
        surfing: searchRequest.activities?.includes('surfing') || false,
        swimming: searchRequest.activities?.includes('swimming') || false,
        waterSports: searchRequest.activities?.includes('waterSports') || false,
        wildlifeWatching: searchRequest.activities?.includes('wildlifeWatching') || false,
        windSports: searchRequest.activities?.includes('windSports') || false,
        custom,
      },
      dump: searchRequest.selected?.dump || false,
      restArea: searchRequest.selected?.restArea || false,
      wifi: searchRequest.selected?.wifi || false,
      potableWater: searchRequest.selected?.potableWater || false,
      propane: searchRequest.selected?.propane || false,
    };
  }

  export function siteCompletePercentage(flow: 'host' | 'boondock' | 'guest', site: ISite, user?: IUser) {
    if (!user && site.user) {
      user = site.user;
    }

    const latestStep = findLatestStep(flow, site, user);
    const steps = findSteps(flow, site, user);

    const currentStep = steps.find((el) => el.type === latestStep);
    if (currentStep) {
      const foundIndex = steps.findIndex((el) => el.type === currentStep.type);
      return toPrecision((foundIndex / (steps.length - 1)) * 100, 0);
    }

    return 0;
  }

  export function findSteps(flow: 'host' | 'boondock' | 'guest', site?: ISite, user?: IUser): ISignUpFlowStep[] {
    let steps: ISignUpFlowStep[];

    if (flow === 'host') {
      if (site?.type === 'standard') {
        steps = HOST_SITE_FLOW.slice();
      } else {
        steps = HOST_BOONDOCK_FLOW.slice();
      }
    } else if (flow === 'boondock') {
      steps = HOST_BOONDOCK_FLOW.slice();
    } else {
      if (user?.selectedRv) {
        if (user?.selectedRv.tent) {
          steps = GUEST_FLOW_TENT.slice();
        } else {
          steps = GUEST_FLOW_RV.slice();
        }
      } else {
        steps = GUEST_FLOW_RV.slice();
      }
    }

    // Make the steps editable.
    return steps.map((step) => ({ ...step }));
  }

  export function findLatestStep(
    flow: 'host' | 'boondock' | 'guest',
    site?: ISite,
    user?: IUser,
  ): FlowStepType | undefined {
    const steps = findSteps(flow, site, user);

    // Will be relying on the array being ordered
    // Check each step's completeness
    steps.reduceRight((prevStep, currentStep) => {
      switch (currentStep.type) {
        case FlowStepType.RoleSelector:
          if (user?.role) {
            currentStep.complete = true;
          }
          break;
        case FlowStepType.Source:
          if (user?.source) {
            currentStep.complete = true;
          }
          break;
        case FlowStepType.ProfilePhone:
          if (user?.photo && user?.phone) {
            currentStep.complete = true;
          }
          break;
        case FlowStepType.SiteTerrain:
          if (site?.landTypes && site?.landTypes.length > 0) {
            currentStep.complete = true;
          }
          break;
        case FlowStepType.SiteAmenities:
          if ((site?.amenities && site.amenities.length > 0) || prevStep?.complete) {
            currentStep.complete = true;
          }
          break;
        case FlowStepType.SiteActivities:
          if ((site?.activities && site.activities.length > 0) || prevStep?.complete) {
            currentStep.complete = true;
          }
          break;
        case FlowStepType.SiteIsolation:
          if (
            site?.type === 'standard' &&
            site?.noise?.level &&
            (site.privacy?.home ||
              site?.privacy?.secluded ||
              site?.privacy?.others ||
              site?.privacy?.secluded ||
              site?.privacy?.neighbor)
          ) {
            currentStep.complete = true;
          }
          if (site?.type === 'boondock' && site?.noise?.level) {
            currentStep.complete = true;
          }
          break;
        case FlowStepType.SiteBoondockLand:
          if ((site?.sizeCars && site?.sizeCars > 0) || (site?.sizeBuses && site?.sizeBuses > 0)) {
            currentStep.complete = true;
          }
          break;
        case FlowStepType.SiteRVs:
          if (
            site?.vehicles?.classA ||
            site?.vehicles?.classA ||
            site?.vehicles?.classB ||
            site?.vehicles?.classC ||
            site?.vehicles?.fifthWheel ||
            site?.vehicles?.travelTrailer ||
            site?.vehicles?.truckCamper ||
            site?.vehicles?.teardrop ||
            site?.vehicles?.popup ||
            prevStep?.complete
          ) {
            currentStep.complete = true;
          }
          break;
        case FlowStepType.SiteDetails:
          if (site?.amenities) {
            const foundParking = site.amenities.find((el) => el.type === 'pullThrough' || el.type === 'backIn');
            if (site?.maxGuests && site?.maxGuests >= 1 && foundParking) {
              currentStep.complete = true;
            }
          }
          break;
        case FlowStepType.SitePrice:
          if (site && (site.price || site.price === 0) && site?.cancellationPolicy !== undefined) {
            currentStep.complete = true;
          }
          break;
        case FlowStepType.SiteBooking:
          if (
            site?.checkIn &&
            site.checkOut &&
            site.minLengthOfStayInDays &&
            site.maxLengthOfStayInDays &&
            (site.reserveDaysInAdvance || site.reserveDaysInAdvance === 0) &&
            site.bookingWindow &&
            site.checkInProcess &&
            site.reservationProcess
          ) {
            currentStep.complete = true;
          }
          break;
        case FlowStepType.SiteRules:
          if ((site?.policies && site.policies.length >= 1) || prevStep?.complete) {
            currentStep.complete = true;
          }
          break;
        case FlowStepType.SiteLocation:
          if (
            ((site?.address &&
              site?.address.address &&
              site.address.city &&
              site.address.state &&
              site.address.zipcode) ||
              (site?.coordinates && site.coordinates.lat && site.coordinates.lon)) &&
            site?.landOwner &&
            site?.landOwner?.self !== undefined &&
            site?.landOwner?.self !== null
          ) {
            currentStep.complete = true;
          }
          break;
        case FlowStepType.SiteDescription:
          if (
            site?.name &&
            site?.name?.length >= 5 &&
            site?.description &&
            site?.description &&
            site?.description?.length >= 150
          ) {
            currentStep.complete = true;
          }
          break;
        case FlowStepType.UserProfilePhoto:
          if (user?.photo && user?.photo?.key?.length > 1) {
            currentStep.complete = true;
          }
          break;
        case FlowStepType.SitePhotos:
          if (site?.photos && site?.photos?.length >= 3) {
            currentStep.complete = true;
          } else if (site?.publishDate !== undefined) {
            currentStep.complete = true;
          }
          break;
        case FlowStepType.UserGarage:
          if (user?.selectedRv && ((user?.selectedRv?.name && user?.selectedRv?.type) || user?.selectedRv?.tent)) {
            currentStep.complete = true;
          }
          break;
        case FlowStepType.UserRv:
          if (user?.selectedRv && user?.selectedRv?.name && user?.selectedRv?.type) {
            currentStep.complete = true;
          }
          break;
        case FlowStepType.UserRvSize:
          if (
            user?.selectedRv &&
            (user?.selectedRv.year ||
              user?.selectedRv.make ||
              user?.selectedRv.model ||
              user?.selectedRv.height ||
              user?.selectedRv.width ||
              user?.selectedRv.length ||
              user?.selectedRv.underwayLength ||
              user?.selectedRv.underwayHeight ||
              user?.selectedRv.underwayWidth ||
              user?.selectedRv.clearance ||
              user?.selectedRv.weight ||
              user?.selectedRv.axleCount)
          ) {
            currentStep.complete = true;
          }
          break;
        case FlowStepType.UserRoadConditions:
          if (
            user?.selectedRv &&
            (user?.selectedRv.roadCondition?.offRoad ||
              user?.selectedRv.roadCondition?.paved ||
              user?.selectedRv.roadCondition?.smoothGravel ||
              user?.selectedRv.roadCondition?.poorGravel ||
              user?.selectedRv.roadCondition?.highClearance)
          ) {
            currentStep.complete = true;
          }
          break;
        case FlowStepType.UserTent:
          if (
            user?.selectedRv &&
            (user?.selectedRv.tent ||
              user?.selectedRv.tentWidth ||
              user?.selectedRv.tentHeight ||
              user?.selectedRv.tentLength ||
              user?.selectedRv.tentCount)
          ) {
            currentStep.complete = true;
          }
          break;
      }

      return currentStep;
    });

    let firstIncompleteStep = undefined;
    for (let x in steps) {
      if (!steps[x].complete) {
        firstIncompleteStep = steps[x];
        break;
      }
    }

    return firstIncompleteStep?.type;
  }
}
