import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AgritourismAuthService } from '../../../auth/auth.service';

@Component({
  selector: 'curbnturf-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class AgritourismLoginComponent {
  loginForm = this.fb.group({
    email: this.fb.control<string>('', [Validators.required, Validators.email]),
    password: this.fb.control<string>('', Validators.required),
  });

  constructor(private authService: AgritourismAuthService, private fb: FormBuilder, private router: Router) {}

  login() {
    if (this.loginForm.valid) {
      this.authService
        .login(this.loginForm.value.email || '', this.loginForm.value.password || '')
        .subscribe(() => this.router.navigate(['', 'auth', 'redirect']));
    }
  }
}
