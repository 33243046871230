import { IAmenity, ILandOwner, IPrivacy, ISite, IUser } from '@curbnturf/objects';

export namespace siteComplete {
  const missingAddress = (contact: IUser | ILandOwner): boolean => {
    return (
      !contact ||
      !contact.firstName ||
      !contact.lastName ||
      !contact.address ||
      !contact.address.address ||
      !contact.address.city ||
      !contact.address.state ||
      !contact.phone
    );
  };

  const testAmenity = (amenities?: IAmenity[]): { messages: string[]; completeness: number } => {
    // amenities always at least 5
    let completeness = 5;
    const messages: string[] = [];

    if (amenities && amenities.find((amenity) => amenity.type === 'pullThrough' || amenity.type === 'backIn')) {
      completeness += 4;
    } else {
      messages.push('Missing Pull Through or Back In Selection.');
    }

    return { messages, completeness };
  };

  const testBookingProcess = (site: ISite): { messages: string[]; completeness: number } => {
    let completeness = 0;
    const messages: string[] = [];

    if (!site) {
      return { messages, completeness };
    }

    if (site.type === 'standard') {
      if (site.reservationProcess) {
        completeness += 3;
      }

      if (site.checkInProcess) {
        completeness += 3;
      }
    }

    return { messages, completeness };
  };

  const testBookingRange = (site: ISite): { messages: string[]; completeness: number } => {
    let completeness = 0;
    const messages: string[] = [];

    if (!site) {
      return { messages, completeness };
    }

    if (site.type === 'standard') {
      if (site.bookingWindow) {
        completeness += 3;
      } else {
        messages.push('Missing Booking Window');
      }

      if (site.reserveDaysInAdvance || site.reserveDaysInAdvance === 0) {
        completeness += 3;
      } else {
        messages.push('Missing Reservation Advance Notice');
      }
    }

    return { messages, completeness };
  };

  const testCheckInOut = (site: ISite): { messages: string[]; completeness: number } => {
    let completeness = 0;
    const messages: string[] = [];

    if (!site) {
      return { messages: ['Missing Check In Time', 'Missing Check Out Time'], completeness };
    }

    if (site.type === 'standard') {
      if (site.checkIn) {
        completeness += 3;
      } else {
        messages.push('Missing Check In Time');
      }

      if (site.checkOut) {
        completeness += 3;
      } else {
        messages.push('Missing Check Out Time');
      }
    }

    return { messages, completeness };
  };

  const testCancellation = (site: ISite): { messages: string[]; completeness: number } => {
    if (site && site.cancellationPolicy) {
      return { messages: [], completeness: 2 };
    } else {
      return { messages: ['Missing Cancellation Policy'], completeness: 0 };
    }
  };

  const testDescriptionText = (site: ISite): { messages: string[]; completeness: number } => {
    if (site && site.description && site.description.length >= 150) {
      return { messages: [], completeness: 4 };
    } else {
      return { messages: ['Missing Description Text'], completeness: 0 };
    }
  };

  const testDirections = (site: ISite): { messages: string[]; completeness: number } => {
    let completeness = 0;
    const messages: string[] = [];

    if (!site) {
      return { messages: ['Missing Directions'], completeness };
    }

    if (site.coordinates) {
      if (site.coordinates.lat) {
        completeness += 2;
      } else {
        messages.push('Missing Latitude Coordinates');
      }

      if (site.coordinates.lon) {
        completeness += 2;
      } else {
        messages.push('Missing Longitude Coordinates');
      }
    }

    return { messages, completeness };
  };

  const testLengthOfStay = (site: ISite): { messages: string[]; completeness: number } => {
    let completeness = 0;
    const messages: string[] = [];

    if (!site) {
      return { messages: ['Missing Minimum Length of Stay', 'Missing Maximum Length of Stay'], completeness };
    }

    if (site.type === 'standard') {
      if (site.minLengthOfStayInDays) {
        completeness += 2;
      } else {
        messages.push('Missing Minimum Length of Stay');
      }

      if (site.maxLengthOfStayInDays) {
        completeness += 2;
      } else {
        messages.push('Missing Maximum Length of Stay');
      }
    }

    return { messages, completeness };
  };

  const testBooking = (site: ISite): { messages: string[]; completeness: number } => {
    let completeness = 0;
    const messages: string[] = [];

    if (!site) {
      return { messages, completeness };
    }

    if (site.type === 'standard') {
      const bookingProcess = testBookingProcess(site);
      completeness += bookingProcess.completeness;
      messages.push(...bookingProcess.messages);

      const bookingRange = testBookingRange(site);
      completeness += bookingRange.completeness;
      messages.push(...bookingRange.messages);

      const lengthOfStay = testLengthOfStay(site);
      completeness += lengthOfStay.completeness;
      messages.push(...lengthOfStay.messages);

      const checkInOut = testCheckInOut(site);
      completeness += checkInOut.completeness;
      messages.push(...checkInOut.messages);
    }

    return { messages, completeness };
  };

  const testLandOwner = (site: ISite): { messages: string[]; completeness: number } => {
    const messages: string[] = [];
    const landOwner = site.landOwner;
    if (!landOwner) {
      return { messages: ['Missing Land Owner'], completeness: 0 };
    }

    if (landOwner.self) {
      return { messages: [], completeness: 2 };
    }

    if (site.type === 'standard' && !landOwner.represent) {
      return { messages: ['Missing Land Owner Information'], completeness: 0 };
    }

    if (landOwner.lastName) {
      if (landOwner.private) {
        if (!missingAddress(landOwner)) {
          return { messages: [], completeness: 2 };
        } else {
          messages.push('Missing Land Owner Address');
        }
      } else {
        return { messages: [], completeness: 2 };
      }
    }

    return { messages: [...messages, 'Missing Complete Land Owner Information'], completeness: 0 };
  };

  const testMaxGuests = (site: ISite): { messages: string[]; completeness: number } => {
    if (site && site.maxGuests) {
      return { messages: [], completeness: 2 };
    } else {
      return { messages: ['Missing Max Guest Number'], completeness: 0 };
    }
  };

  const testName = (site: ISite): { messages: string[]; completeness: number } => {
    if (site && site.name && site.name.length >= 8) {
      return { messages: [], completeness: 2 };
    } else {
      return { messages: ['Missing Site Name'], completeness: 0 };
    }
  };

  const testNoise = (site: ISite): { messages: string[]; completeness: number } => {
    if (site.type === 'boondock') {
      return { messages: [], completeness: 0 };
    }

    let returnValue = 0;
    const messages: string[] = [];

    if (site && site.noise && site.noise.level) {
      returnValue += 4;
    } else {
      messages.push('Missing Noise Selection');
    }

    return { messages, completeness: returnValue };
  };

  const testPhotos = (site: ISite): { messages: string[]; completeness: number } => {
    let completeness = 0;
    const messages: string[] = [];

    if (!site || site.type === 'boondock') {
      return { messages: ['Missing or Incomplete set of Photos'], completeness };
    }

    if (site.photos && site.photos.length >= 3) {
      completeness += 6;
    } else {
      messages.push('Missing or Incomplete set of Photos');
    }

    return { messages, completeness };
  };

  const testPrice = (site: ISite): { messages: string[]; completeness: number } => {
    if (site && (site.price || site.price === 0)) {
      return { messages: [], completeness: 8 };
    } else {
      return { messages: ['Missing Site Price'], completeness: 0 };
    }
  };

  const testPricing = (site: ISite): { messages: string[]; completeness: number } => {
    let completeness = 0;
    const messages: string[] = [];

    if (!site) {
      return { messages: ['Missing Site for Pricing'], completeness };
    }

    if (site.type === 'standard') {
      const cancellation = testCancellation(site);
      completeness += cancellation.completeness;
      messages.push(...cancellation.messages);

      const maxGuests = testMaxGuests(site);
      completeness += maxGuests.completeness;
      messages.push(...maxGuests.messages);

      const price = testPrice(site);
      completeness += price.completeness;
      messages.push(...price.messages);
    }

    return { messages, completeness };
  };

  const testPrivacy = (privacy?: IPrivacy): { messages: string[]; completeness: number } => {
    const messages: string[] = [];
    if (privacy && (privacy.home || privacy.neighbor || privacy.others || privacy.secluded)) {
      return { messages, completeness: 4 };
    }

    messages.push('Missing Privacy Selection.');
    return { messages, completeness: 0 };
  };

  const testTerrain = (site: ISite): { messages: string[]; completeness: number } => {
    if (site.landTypes && site.landTypes.length > 0) {
      return { messages: [], completeness: 4 };
    } else {
      return { messages: ['Missing Terrain Information'], completeness: 0 };
    }
  };

  const testDescription = (site: ISite): { messages: string[]; completeness: number } => {
    let completeness = 0;
    const messages: string[] = [];

    if (!site) {
      return { messages: ['Missing Site'], completeness };
    }

    const name = testName(site);
    completeness += name.completeness;
    messages.push(...name.messages);

    const descriptionTest = testDescriptionText(site);
    completeness += descriptionTest.completeness;
    messages.push(...descriptionTest.messages);

    const landOwner = testLandOwner(site);
    completeness += landOwner.completeness;
    messages.push(...landOwner.messages);

    const terrain = testTerrain(site);
    completeness += terrain.completeness;
    messages.push(...terrain.messages);

    const photos = testPhotos(site);
    completeness += photos.completeness;
    messages.push(...photos.messages);

    return { messages, completeness };
  };

  const testUserName = (user: IUser): { messages: string[]; completeness: number } => {
    let completeness = 0;
    const messages: string[] = [];

    if (!user) {
      return { messages: ['Missing User Profile'], completeness };
    }

    if (user.firstName) {
      completeness += 2;
    } else {
      messages.push('Missing Profile First Name');
    }

    if (user.lastName) {
      completeness += 2;
    } else {
      messages.push('Missing Profile Last Name');
    }

    return { messages, completeness };
  };

  const testUserPhoto = (user: IUser): { messages: string[]; completeness: number } => {
    let completeness = 0;
    const messages: string[] = [];

    if (!user) {
      return { messages: ['Missing User Profile'], completeness };
    }

    if (user.photo?.key) {
      completeness += 4;
    } else {
      messages.push('Missing User Profile Photo');
    }

    return { messages, completeness };
  };

  const testUserPhone = (user: IUser): { messages: string[]; completeness: number } => {
    let completeness = 0;
    const messages: string[] = [];

    if (!user) {
      return { messages: ['Missing User Profile'], completeness };
    }

    if (user.phone) {
      completeness += 4;
    } else {
      messages.push('Missing Profile Phone Number');
    }

    return { messages, completeness };
  };

  export const userProfilePercentComplete = (user?: IUser): { messages: string[]; completeness: number } => {
    let completeness = 4;
    const messages: string[] = [];

    if (!user) {
      return { messages: ['User Profile Missing'], completeness };
    }

    const userName = testUserName(user);
    completeness += userName.completeness;
    messages.push(...userName.messages);

    const userPhoto = testUserPhoto(user);
    completeness += userPhoto.completeness;
    messages.push(...userPhoto.messages);

    const userPhone = testUserPhone(user);
    completeness += userPhone.completeness;
    messages.push(...userPhone.messages);

    return { messages, completeness };
  };

  const testVehicles = (site: ISite): { messages: string[]; completeness: number } => {
    const vehicles = site.vehicles;
    const messages: string[] = [];
    if (
      vehicles &&
      (site.tentOnly ||
        vehicles.classA ||
        vehicles.classB ||
        vehicles.classC ||
        vehicles.fifthWheel ||
        vehicles.travelTrailer ||
        vehicles.teardrop ||
        vehicles.truckCamper ||
        vehicles.popup)
    ) {
      return { messages, completeness: 4 };
    }

    messages.push('Missing Vehicle Specifications');
    return { messages, completeness: 0 };
  };

  const testOverview = (site: ISite): { messages: string[]; completeness: number } => {
    let completeness = 0;
    const messages: string[] = [];

    if (!site) {
      return { messages, completeness };
    }

    // on site activities
    completeness += 4;

    // off site activities
    completeness += 2;

    if (site.type === 'standard') {
      const amenities = testAmenity(site.amenities);
      completeness += amenities.completeness;
      messages.push(...amenities.messages);

      const vehicles = testVehicles(site);
      completeness += vehicles.completeness;
      messages.push(...vehicles.messages);

      const privacy = testPrivacy(site.privacy);
      completeness += privacy.completeness;
      messages.push(...privacy.messages);

      const noise = testNoise(site);
      completeness += noise.completeness;
      messages.push(...noise.messages);
    }

    return { messages, completeness };
  };

  export const accommodationsCompleted = (site: ISite): boolean => {
    const completeness = testAmenity(site.amenities).completeness;
    if (site.type === 'boondock') {
      return completeness >= 4;
    }

    return completeness >= 8;
  };

  export const bookingProcessCompleted = (site: ISite): boolean => {
    const completeness = testBookingProcess(site).completeness;
    if (site.type === 'boondock') {
      return completeness >= 0;
    }

    return completeness >= 4;
  };

  export const bookingRangeCompleted = (site: ISite): boolean => {
    const completeness = testBookingRange(site).completeness;
    if (site.type === 'boondock') {
      return completeness >= 0;
    }

    return completeness >= 3;
  };

  export const checkInOutCompleted = (site: ISite): boolean => {
    const completeness = testCheckInOut(site).completeness;
    if (site.type === 'boondock') {
      return completeness >= 0;
    }

    return completeness >= 4;
  };

  export const cancellationCompleted = (site: ISite): boolean => {
    const completeness = testCancellation(site).completeness;

    if (site.type === 'boondock') {
      return completeness >= 0;
    }

    return completeness >= 2;
  };

  export const descriptionTextCompleted = (site: ISite): boolean => {
    const completeness = testDescriptionText(site).completeness;

    return completeness >= 4;
  };
  export const directionsCompleted = (site: ISite): boolean => {
    const completeness = testDirections(site).completeness;

    return completeness >= 4;
  };

  export const landOwnerCompleted = (site: ISite): boolean => {
    const completeness = testLandOwner(site).completeness;

    return completeness >= 2;
  };

  export const lengthOfStayCompleted = (site: ISite): boolean => {
    const completeness = testLengthOfStay(site).completeness;
    if (site.type === 'boondock') {
      return completeness >= 0;
    }

    return completeness >= 4;
  };

  export const maxGuestsCompleted = (site: ISite): boolean => {
    const completeness = testMaxGuests(site).completeness;

    if (site.type === 'boondock') {
      return completeness >= 0;
    }

    return completeness >= 2;
  };

  export const nameCompleted = (site: ISite): boolean => {
    const completeness = testName(site).completeness;

    return completeness >= 2;
  };

  export const noiseCompleted = (site: ISite): boolean => {
    const completeness = testNoise(site).completeness;

    if (site.type === 'boondock') {
      return completeness >= 0;
    }

    return completeness >= 4;
  };

  export const overviewCompleted = (site: ISite): boolean => {
    const completeness = testOverview(site).completeness;
    if (site.type === 'boondock') {
      return completeness >= 6;
    }

    return completeness >= 26; // 4 + 2 + 12 + 4
  };

  export const photosCompleted = (site: ISite): boolean => {
    const completeness = testPhotos(site).completeness;
    if (site.type === 'boondock') {
      return completeness >= 0;
    }

    return completeness >= 6;
  };

  export const priceCompleted = (site: ISite): boolean => {
    const completeness = testPrice(site).completeness;

    if (site.type === 'boondock') {
      return completeness >= 0;
    }

    return completeness >= 4;
  };

  export const privacyCompleted = (site: ISite): boolean => {
    const completeness = testPrivacy(site.privacy).completeness;
    if (site.type === 'boondock') {
      return completeness >= 0;
    }

    return completeness >= 4;
  };

  export const bookingCompleted = (site: ISite): boolean => {
    return (
      bookingProcessCompleted(site) &&
      bookingRangeCompleted(site) &&
      checkInOutCompleted(site) &&
      lengthOfStayCompleted(site)
    );
  };

  export const tentAmenity = (site: ISite): IAmenity | undefined => {
    if (!site.amenities) {
      return;
    }

    return site.amenities.find((amenity) => amenity.type === 'tents');
  };

  export const tentExtraIsSet = (site: ISite): boolean => {
    if (!site.extras) {
      return false;
    }

    const tentExtra = site.extras.find((extra) => extra.label === 'extraTent');

    if (!tentExtra || !tentExtra.count || (!tentExtra.price && tentExtra.price !== 0)) {
      return false;
    }

    return tentExtra.count > 0 && tentExtra.price >= 0;
  };

  export const tentCompleted = (site: ISite): boolean => {
    if (tentAmenity(site)) {
      return tentExtraIsSet(site);
    }

    return true;
  };

  export const terrainCompleted = (site: ISite): boolean => {
    const completeness = testTerrain(site).completeness;
    return completeness >= 4;
  };

  export const descriptionCompleted = (site: ISite): boolean => {
    return (
      nameCompleted(site) &&
      descriptionTextCompleted(site) &&
      landOwnerCompleted(site) &&
      terrainCompleted(site) &&
      photosCompleted(site)
    );
  };

  export const userNameCompleted = (user: IUser): boolean => {
    return testUserName(user).completeness >= 4;
  };

  export const userPhotoCompleted = (user: IUser): boolean => {
    return testUserPhoto(user).completeness >= 4;
  };

  export const userPhoneCompleted = (user: IUser): boolean => {
    return testUserPhone(user).completeness >= 4;
  };

  export const userProfileCompleted = (user: IUser): boolean => {
    return userNameCompleted(user) && userPhotoCompleted(user) && userPhoneCompleted(user);
  };

  export const userCompleted = (user: IUser): boolean => {
    return userProfileCompleted(user) && userPhotoCompleted(user);
  };

  export const vehiclesCompleted = (site: ISite): boolean => {
    const completeness = testVehicles(site).completeness;
    if (site.type === 'boondock') {
      return completeness >= 0;
    }

    return completeness >= 4;
  };

  export const sitePercentComplete = (site: ISite): { messages: string[]; completeness: number } => {
    let completeness = 0;
    const messages: string[] = [];

    if (!site) {
      return { messages: ['Missing Site'], completeness };
    }

    const overview = testOverview(site);
    completeness += overview.completeness;
    messages.push(...overview.messages);

    const description = testDescription(site);
    completeness += description.completeness;
    messages.push(...description.messages);

    const directions = testDirections(site);
    completeness += directions.completeness;
    messages.push(...directions.messages);

    const profilePercentComplete = userProfilePercentComplete(site.user);
    completeness += profilePercentComplete.completeness;
    messages.push(...profilePercentComplete.messages);

    if (site.type) {
      completeness += 2;
    }

    if (site.type === 'standard') {
      const pricing = testPricing(site);
      completeness += pricing.completeness;
      messages.push(...pricing.messages);

      const booking = testBooking(site);
      completeness += booking.completeness;
      messages.push(...booking.messages);

      if (tentCompleted(site)) {
        completeness += 2;
      } else {
        messages.push('Missing Test Setup');
      }
    }

    if (site.type === 'boondock') {
      return { messages, completeness: Math.floor((completeness / 40) * 100) };
    }

    return { messages, completeness: Math.floor((completeness / 103) * 100) };
  };

  export const completed = (site: ISite): boolean => {
    return sitePercentComplete(site).completeness >= 100;
  };

  export const siteCompleteMessages = (site: ISite): string[] => {
    const messages: string[] = [];

    if (!site) {
      return ['Missing Site'];
    }

    messages.push(...testOverview(site).messages);
    messages.push(...testDescription(site).messages);
    messages.push(...testDirections(site).messages);
    messages.push(...userProfilePercentComplete(site.user).messages);

    if (!site.type) {
      messages.push('No Site Type Selected.');
    }

    if (site.type === 'standard') {
      messages.push(...testPricing(site).messages);
      messages.push(...testBooking(site).messages);
      if (tentCompleted(site)) {
        messages.push('Missing Test Setup');
      }
    }

    return messages;
  };

  export const pricingCompleted = (site: ISite): boolean => {
    if (site.type === 'boondock') {
      return true;
    }

    return cancellationCompleted(site) && maxGuestsCompleted(site) && priceCompleted(site) && tentCompleted(site);
  };
}
