<div class="content-container bottom">
  <div class="card-content-outer">
    <div class="challenge-course-footer">
      <a
        id="w-node-_98720280-5e0a-09c4-cae5-e9ed92ef6049-d690a9ed"
        href="https://www.curbnturf.com"
        class="cnt-home w-inline-block"
      >
        <img
          src="/img/CNT_Logo_V_White.svg"
          loading="lazy"
          id="w-node-_98720280-5e0a-09c4-cae5-e9ed92ef604a-d690a9ed"
          alt=""
          class="cnt-logo"
        />
      </a>
      <div id="w-node-_4e677664-98cf-5ee3-6434-2d25d690a9f0-d690a9ed" class="challenge-emphasis">
        Five day agritourism Challenge presented by Curbnturf.<br />
      </div>
      <div id="w-node-_4e677664-98cf-5ee3-6434-2d25d690a9f3-d690a9ed" class="challenge-footer-inner">
        <a
          id="w-node-_4e677664-98cf-5ee3-6434-2d25d690a9f4-d690a9ed"
          href="https://www.curbnturf.com/terms"
          class="copy-white"
        >
          Terms &amp; Conditions
        </a>
        <a
          id="w-node-_4e677664-98cf-5ee3-6434-2d25d690a9f6-d690a9ed"
          href="https://www.curbnturf.com/terms/privacy"
          class="copy-white"
        >
          Privacy Policy
        </a>
      </div>
      <div id="w-node-_4e677664-98cf-5ee3-6434-2d25d690a9f8-d690a9ed" class="copy-wrapper">
        <div class="icon-copywrite-white">
          <div class="w-embed">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 24 24"
              style="enable-background: new 0 0 24 24"
              xml:space="preserve"
            >
              <style type="text/css">
                .icon-copyright-white {
                  fill: #ffffff;
                }
              </style>
              <g>
                <path
                  class="icon-copyright-white"
                  d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M12,21.7c-5.4,0-9.7-4.3-9.7-9.7
  S6.6,2.3,12,2.3s9.7,4.3,9.7,9.7S17.4,21.7,12,21.7z"
                ></path>
                <g>
                  <path
                    class="icon-copyright-white"
                    d="M6.9,12.1c0-3.2,2.4-5.4,5.5-5.4c2.4,0,3.7,1.3,4.5,2.6L15,10.1c-0.4-0.9-1.4-1.5-2.6-1.5
    c-1.8,0-3.2,1.5-3.2,3.4s1.4,3.4,3.2,3.4c1.2,0,2.1-0.7,2.6-1.5l1.9,0.9c-0.8,1.3-2.1,2.6-4.5,2.6C9.4,17.5,6.9,15.3,6.9,12.1z"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div class="text-copyright alt">2023 CurbNTurf, Inc. All rights reserved.</div>
      </div>
    </div>
  </div>
</div>
