<div class="w-form">
  <form *ngIf="!formSummitted" [formGroup]="registerForm" (ngSubmit)="register()" class="login-form">
    <div id="w-node-_8428f65b-c347-a560-79ca-3e01dadee97f-d690a9ed" class="login-input">
      <label for="firstName" class="field-label">First Name</label>
      <input
        [required]="true"
        formControlName="firstName"
        name="firstName"
        id="firstName"
        type="text"
        class="text-input w-input"
        placeholder="First Name"
      />
    </div>
    <div id="w-node-_8428f65b-c347-a560-79ca-3e01dadee983-d690a9ed" class="login-input">
      <label for="lastName" class="field-label">Last Name</label>
      <input
        [required]="true"
        formControlName="lastName"
        name="lastName"
        id="lastName"
        type="text"
        class="text-input w-input"
        placeholder="Last Name"
      />
    </div>
    <div id="w-node-_8428f65b-c347-a560-79ca-3e01dadee987-d690a9ed" class="login-input">
      <label for="email" class="field-label">Email</label>
      <input
        [email]="true"
        [required]="true"
        formControlName="email"
        name="email"
        id="email"
        type="email"
        class="text-input w-input"
        placeholder="Enter email"
      />
    </div>
    <div id="w-node-_8428f65b-c347-a560-79ca-3e01dadee98b-d690a9ed" class="login-input">
      <label for="password" class="field-label">Password</label>
      <input
        [required]="true"
        formControlName="password"
        name="password"
        id="password"
        type="password"
        class="text-input w-input"
        placeholder="Create Password"
      />
    </div>
    <button
      type="submit"
      *ngIf="modal"
      id="w-node-_8428f65b-c347-a560-79ca-3e01dadee990-d690a9ed"
      class="button trinidad w-button"
    >
      Submit
    </button>
    <button
      type="submit"
      *ngIf="!modal"
      id="w-node-_8428f65b-c347-a560-79ca-3e01dadee990-d690a9ed"
      class="button w-button"
    >
      Submit
    </button>
  </form>
  <div *ngIf="formSummitted" class="w-form-done">
    <div>Thank you! Your submission has been received and an email with directions has been sent!</div>
  </div>
  <div *ngIf="formError" class="w-form-fail">
    <div>Oops! Something went wrong while submitting the form.</div>
  </div>
</div>
