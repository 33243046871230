import { environment } from '@curbnturf/environment';
import { FlowStepType, ISignUpFlowStep } from '@curbnturf/objects';
import { Action } from '@ngrx/store';

export const PRODUCTION = environment.production;
export const PROGRAM = environment.program;
export const BASE_URL = environment.baseUrl;
export const BASE_API_URL = environment.apiUrl + '/api/v1/';
export const POI_API_URL = environment.poiApiUrl;
export const SEARCH_API_URL = environment.searchApiUrl;
export const VAPID_PUBLIC = environment.vapidPublic;
export const IMAGE_URL = environment.imageUrl;
export const AWS_REGION = environment.awsRegion;
export const COGNITO_USER_POOL_ID = environment.cognitoUserPoolId;
export const COGNITO_CLIENT_ID = environment.cognitoClientId;
export const COGNITO_DOMAIN = environment.cognitoDomain;
export const COGNITO_CALLBACK = environment.cognitoCallback;
export const COGNITO_CALLBACK_IOS = environment.cognitoCallbackIOS;
export const COGNITO_CALLBACK_APP = environment.cognitoCallbackApp;
export const CONVERGE_GET_TOKEN_URL = environment.convergeGetTokenUrl;
export const HERE_API_KEY = environment.mapApiKey;
export const HERE_OLD_APP_CODE = environment.mapOldAppCode;
export const HERE_OLD_APP_ID = environment.mapOldAppId;
export const GOOGLE_ANALYTICS = environment.gaCode;
export const GOOGLE_ANALYTICS_4 = environment.ga4Code;
export const FEEDBACK_INCENTIVE = 5;
export const FEEDBACK_INCENTIVE_DAYS = 14;
export const MINIMUM_FEE = 5;
export const FEE_PERCENTAGE = 0.15;
export const GROW_PERCENTAGE = 0.2;
export const TOTAL_GROW_RATE = FEE_PERCENTAGE * GROW_PERCENTAGE;
export const RAF_CREDIT = 25;
export const LOGO_URL = '/img/CNT_Logo_V_Orange.svg';
export const CARETAKER_TEDDY_ID = 86;
export const REQUEST_PAYOUT_LIMIT = 50;
export const PRELOAD_CACHE_DEFAULT_PERIOD = { days: 7 };
export const DEFAULT_MAP_LATITUDE = 39.16770933614724;
export const DEFAULT_MAP_LONGITUDE = -120.14544457543407;
export const DEFAULT_MAP_ZOOM_LEVEL = 10;
export const DEFAULT_MAP_VIEWPORT_DISTANCE = 500;
export const ANIMATE_MAP = true;
export const MILE_TO_METERS_RATIO = 1609;
export const GEOLOCATION_TIMEOUT = 10000;
export const GEOLOCATION_UPDATE_INTERVAL = 60000 * 5; // 5 minutes

export const SITE_INCOMPLETE_ALLOWED_DAYS = 30;

export const NGRX_BUCKETS = {
  activity: 'activity',
  addressBook: 'addressBook',
  alert: 'alert',
  appConfig: 'appConfig',
  auth: 'auth',
  authModal: 'authModal',
  checklist: 'checklist',
  listingSearch: 'listingSearch',
  location: 'location',
  log: 'log',
  message: 'message',
  network: 'network',
  order: 'order',
  poi: 'poiAutocomplete',
  prefetch: 'prefetch',
  rating: 'rating',
  reservation: 'reservation',
  reviews: 'reviews',
  router: 'router',
  schedule: 'schedule',
  signUpState: 'signUpState',
  signUpStateHelp: 'signUpStateHelp',
  site: 'site',
  storage: 'storage',
  tripPlanner: 'tripPlanner',
  urlControl: 'urlControl',
  user: 'user',
  webMap: 'webMap',
};

export const NO_ACTION = 'NO_ACTION';
export const NGRX_NO_ACTION: Action = {
  type: NO_ACTION,
};

export const DEFAULT_IMAGE_URL = '/img/caravan.svg';
export const DEFAULT_IMAGE_4X3_URL = '/img/caravan-4x3.svg';
export const DEFAULT_IMAGE_FLAT_URL = '/img/caravan-flat.svg';
export const DEFAULT_IMAGE_TITLE_URL = '/img/caravan-title.svg';
export const DEFAULT_IMAGE_MOTORHOME_URL = '/img/motorhome.svg';


export const PATHS = {
  root: '/',
  login: '/auth/login',
  redirect: '/auth/redirect',
  garage: '/user-dashboard/dashboard;section=garage',
  trips: '/user-dashboard/dashboard;section=trip',
  search: '/search',
  listings: '/listings',
  signupStart: '/sign-up/start-selector', // guest or host
  signupStandard: '/sign-up/source',
  signupBoondock: '/boondock-sign-up',
  signupGuest: '/guest-sign-up',
};

export const OFFLINE_DATABASE_NAME = environment?.offlineDatabaseName;

export const NEW_POI_MODAL_ID = 'list-activity-new-modal';
export const NEW_SITE_MODAL_ID = 'new-listing-modal';

export const NETWORK_STATUS_DEBOUNCE = 500;

// Sign Up Flow Step Templates
export const HOST_SITE_FLOW: ISignUpFlowStep[] = [
  { type: FlowStepType.RoleSelector, title: 'Select Role', route: ['', 'sign-up', 'start-selector'], complete: false },
  { type: FlowStepType.Source, title: 'Referral Source', route: ['', 'sign-up', 'source'], complete: false },
  { type: FlowStepType.ProfilePhone, title: 'Phone', route: ['', 'sign-up', 'phone'], complete: false },
  { type: FlowStepType.SiteTerrain, title: 'Site Terrain', route: ['', 'sign-up', 'type'], complete: false },
  { type: FlowStepType.SiteAmenities, title: 'Site Amenities', route: ['', 'sign-up', 'amenities'], complete: false },
  {
    type: FlowStepType.SiteActivities,
    title: 'Site Activities',
    route: ['', 'sign-up', 'activities'],
    complete: false,
  },
  {
    type: FlowStepType.SiteIsolation,
    title: 'Site Conditions/Isolation',
    route: ['', 'sign-up', 'isolation'],
    complete: false,
  },
  { type: FlowStepType.SiteRVs, title: 'Vehicles Allowed On Site', route: ['', 'sign-up', 'rvs'], complete: false },
  { type: FlowStepType.SiteDetails, title: 'Site Details', route: ['', 'sign-up', 'details'], complete: false },
  { type: FlowStepType.SitePrice, title: 'Site Pricing', route: ['', 'sign-up', 'price'], complete: false },
  { type: FlowStepType.SiteBooking, title: 'Site Booking Details', route: ['', 'sign-up', 'booking'], complete: false },
  { type: FlowStepType.SiteRules, title: 'Site Policy and Rules', route: ['', 'sign-up', 'rules'], complete: false },
  { type: FlowStepType.SiteLocation, title: 'Site Address', route: ['', 'sign-up', 'address'], complete: false },
  {
    type: FlowStepType.SiteDescription,
    title: 'Site Name and Description',
    route: ['', 'sign-up', 'name'],
    complete: false,
  },
  {
    type: FlowStepType.UserProfilePhoto,
    title: 'Profile Photo',
    route: ['', 'sign-up', 'profile-photo'],
    complete: false,
  },
  { type: FlowStepType.SitePhotos, title: 'Site Photos', route: ['', 'sign-up', 'photos'], complete: false },
  { type: FlowStepType.SitePublish, title: 'Publish Your Site', route: ['', 'sign-up', 'publish'], complete: false },
];

export const HOST_BOONDOCK_FLOW: ISignUpFlowStep[] = [
  { type: FlowStepType.RoleSelector, title: 'Select Role', route: ['', 'sign-up', 'start-selector'], complete: false },
  { type: FlowStepType.Source, title: 'Referral Source', route: ['', 'sign-up', 'source'], complete: false },
  { type: FlowStepType.ProfilePhone, title: 'Phone', route: ['', 'sign-up', 'phone'], complete: false },
  { type: FlowStepType.SiteTerrain, title: 'Site Terrain', route: ['', 'sign-up', 'type'], complete: false },
  { type: FlowStepType.SiteAmenities, title: 'Site Amenities', route: ['', 'sign-up', 'amenities'], complete: false },
  {
    type: FlowStepType.SiteActivities,
    title: 'Exclusive Site Activities',
    route: ['', 'sign-up', 'activities'],
    complete: false,
  },
  {
    type: FlowStepType.SiteIsolation,
    title: 'Site Conditions/Isolation',
    route: ['', 'sign-up', 'isolation'],
    complete: false,
  },
  { type: FlowStepType.SiteBoondockLand, title: 'Site Size', route: ['', 'sign-up', 'land'], complete: false },
  { type: FlowStepType.SiteLocation, title: 'Site Address', route: ['', 'sign-up', 'address'], complete: false },
  {
    type: FlowStepType.SiteDescription,
    title: 'Site Name and Description',
    route: ['', 'sign-up', 'name'],
    complete: false,
  },
  {
    type: FlowStepType.UserProfilePhoto,
    title: 'Profile Photo',
    route: ['', 'sign-up', 'profile-photo'],
    complete: false,
  },
  { type: FlowStepType.SitePhotos, title: 'Site Photos', route: ['', 'sign-up', 'photos'], complete: false },
  { type: FlowStepType.SitePublish, title: 'Publish Your Site', route: ['', 'sign-up', 'publish'], complete: false },
];

export const GUEST_FLOW_RV: ISignUpFlowStep[] = [
  { type: FlowStepType.RoleSelector, title: 'Select Role', route: ['', 'sign-up', 'start-selector'], complete: false },
  { type: FlowStepType.Source, title: 'Referral Source', route: ['', 'sign-up', 'source'], complete: false },
  { type: FlowStepType.ProfilePhone, title: 'Phone ', route: ['', 'sign-up', 'phone'], complete: false },
  {
    type: FlowStepType.UserProfilePhoto,
    title: 'Profile Photo',
    route: ['', 'sign-up', 'profile-photo'],
    complete: false,
  },
  { type: FlowStepType.UserGarage, title: 'User Garage', route: ['', 'sign-up', 'create-garage'], complete: false },
  { type: FlowStepType.UserRv, title: 'Your Vehicle', route: ['', 'sign-up', 'rv'], complete: false },
  {
    type: FlowStepType.UserRvSize,
    title: 'Your Vehicle Specifications',
    route: ['', 'sign-up', 'rv-size'],
    complete: false,
  },
  {
    type: FlowStepType.UserRoadConditions,
    title: 'Road Conditions',
    route: ['', 'sign-up', 'road-conditions'],
    complete: false,
  },
  { type: FlowStepType.UserComplete, title: 'Complete', route: ['', 'sign-up', 'guest-complete'], complete: false },
];

export const GUEST_FLOW_TENT: ISignUpFlowStep[] = [
  {
    type: FlowStepType.RoleSelector,
    title: 'Select Default Role',
    route: ['', 'sign-up', 'start-selector'],
    complete: false,
  },
  { type: FlowStepType.Source, title: 'Source', route: ['', 'sign-up', 'source'], complete: false },
  { type: FlowStepType.ProfilePhone, title: 'Complete Your Profile', route: ['', 'sign-up', 'phone'], complete: false },
  {
    type: FlowStepType.UserProfilePhoto,
    title: 'Profile Photo',
    route: ['', 'sign-up', 'profile-photo'],
    complete: false,
  },
  { type: FlowStepType.UserGarage, title: 'User Garage', route: ['', 'sign-up', 'create-garage'], complete: false },
  { type: FlowStepType.UserTent, title: 'Tent Setup', route: ['', 'sign-up', 'tent'], complete: false },
  {
    type: FlowStepType.UserRoadConditions,
    title: 'Road Conditions',
    route: ['', 'sign-up', 'road-conditions'],
    complete: false,
  },
  { type: FlowStepType.UserComplete, title: 'Complete', route: ['', 'sign-up', 'guest-complete'], complete: false },
];
