import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_API_URL, IUser } from '@curbnturf/entities';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AgritourismLocalStorageService } from './local-storage.service';

const AG_API_URL = BASE_API_URL + 'agritourism';

@Injectable({
  providedIn: 'root',
})
export class AgritourismService {
  constructor(private http: HttpClient, private localStorageService: AgritourismLocalStorageService) {}

  public enroll(id: number): Observable<IUser> {
    return this.http.post<IUser>(`${AG_API_URL}/enroll/${id}`, {});
  }

  public complete(dayNumber: number): Observable<IUser> {
    return this.http.post<IUser>(`${AG_API_URL}/complete/${dayNumber}`, {}).pipe(
      tap((user) => {
        this.localStorageService.user = { ...this.localStorageService.user, ...user };
      }),
    );
  }
}
