import { Component, OnInit } from '@angular/core';
import { AgritourismAuthService } from '../../../auth/auth.service';

@Component({
  selector: 'curbnturf-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class AgritourismLogoutComponent implements OnInit {
  constructor(private authService: AgritourismAuthService) {}

  ngOnInit() {
    this.authService.logout().subscribe();
  }
}
