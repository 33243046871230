import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { IUser } from '@curbnturf/entities';

@Injectable({
  providedIn: 'root',
})
export class AgritourismLocalStorageService {
  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  get user(): IUser | undefined {
    try {
      if (isPlatformBrowser(this.platformId)) {
        const userStorage = localStorage.getItem('currentUser');
        return userStorage && JSON.parse(userStorage);
      }
    } catch (err) {
      throw err;
    }

    return;
  }

  set user(user: IUser | undefined) {
    try {
      if (isPlatformBrowser(this.platformId)) {
        if (user) {
          const existingUser = this.user;
          const reducedUser = {
            ...existingUser,
            ...user,
            client: undefined,
          };

          localStorage.setItem('currentUser', JSON.stringify(reducedUser));
        } else {
          localStorage.removeItem('currentUser');
        }
      }
    } catch (err) {
      throw err;
    }
  }

  get redirectUrl(): string | null {
    try {
      if (isPlatformBrowser(this.platformId)) {
        return localStorage.getItem('redirectUrl') || '';
      }
    } catch (err) {
      throw err;
    }

    return null;
  }

  set redirectUrl(path: string | null) {
    try {
      if (isPlatformBrowser(this.platformId)) {
        if (path || path === '') {
          localStorage.setItem('redirectUrl', path);
        } else {
          localStorage.removeItem('redirectUrl');
        }
      }
    } catch (err) {
      throw err;
    }
  }

  clear(): void {
    localStorage.clear();
  }
}
