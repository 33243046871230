<a *ngIf="!loggedIn" [routerLink]="['', 'auth', 'login']" class="nav-link-hero nav w-inline-block">
  <div class="login-wrapper">
    <div class="icon-login">
      <div class="w-embed">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6C9.79086 6 8 7.79086 8 10C8 12.2091 9.79086 14 12 14ZM12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12Z"
            fill="currentColor"
          ></path>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.9261 20.0557C20.397 18.235 22 15.3047 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 15.3047 3.60301 18.235 6.07388 20.0557C6.11934 20.0892 6.16509 20.1223 6.21112 20.1551C6.65997 20.4742 7.1363 20.7573 7.63587 21C8.95464 21.6407 10.4354 22 12 22C13.5646 22 15.0454 21.6407 16.3641 21C16.3909 20.987 16.4177 20.9738 16.4443 20.9606C16.9657 20.7015 17.4613 20.3982 17.9261 20.0557ZM17.2287 18.055C18.926 16.588 20 14.4194 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 14.4194 5.07401 16.588 6.77128 18.055C7.80066 16.2313 9.75654 15 12 15C14.2435 15 16.1993 16.2313 17.2287 18.055ZM15.5567 19.1679C14.8921 17.8803 13.5488 17 12 17C10.4512 17 9.10792 17.8803 8.44332 19.1679C9.51467 19.7005 10.7224 20 12 20C13.2776 20 14.4853 19.7005 15.5567 19.1679Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <div>Log in</div>
  </div>
</a>
<a *ngIf="loggedIn" [routerLink]="['', 'course']" class="nav-link-hero nav w-inline-block">
  <div class="login-wrapper">
    <div>To Course</div>
  </div>
</a>


<div class="content-container top">
  <div class="card-content-outer">
    <div class="card-content">
      <div class="overflow-hidden">
        <h1 class="banner-title">Want To Milk Your Land For All Its Worth?</h1>
      </div>
      <div class="banner-subheading">
        <div class="banner-test-item">
          <div class="banner-test-item-title">Join Our 5-Day Challenge Course!</div>
        </div>
      </div>
    </div>
    <div class="banner-button-content">
      <div class="overflow-hidden">
        <p class="banner-paragraph">Don&#x27;t be a chicken, reserve your spot now and start raking in the dough!</p>
      </div>
      <div class="banner-button">
        <div class="btn-wrapper">
          <div data-w-id="60296188-123f-77d7-3996-6ba954933e1b" class="button-hover-wrap">
            <div class="button-icon left">
              <img width="18" alt="" src="/img/arrow-24px-blk.svg" loading="lazy" class="image-icon" />
            </div>
            <a
              (click)="signUpModal()"
              data-w-id="60296188-123f-77d7-3996-6ba954933e1e"
              class="button-primary w-inline-block"
            >
              <div class="button-content">
                <div class="button-color">Get started for Free</div>
              </div>
            </a>
            <div data-w-id="60296188-123f-77d7-3996-6ba954933e22" class="button-icon right">
              <img width="18" alt="" src="/img/arrow-24px-blk.svg" loading="lazy" class="image-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="content-container">
  <div class="card-content-outer nandor">
    <div class="banner-content-grid">
      <h2 id="w-node-_429f1b06-f66a-ef61-4559-528c997744b2-b7eace21" class="h2-head center">
        Ready to Get Dirt-y? Join Our 5-Day Challenge to Turn Your Land into a Profitable Paradise.
      </h2>
      <div id="w-node-_02b0a1b3-f361-f336-1518-c88338365947-b7eace21" class="line-div"></div>
      <div class="banner-content-inner">
        <p class="paragraph-large">
          Get ready to turn your land into a profitable agritourism business with our free 5-day challenge course!
          Whether you&#x27;re a landowner or an agribusiness professional, we&#x27;re here to help you tap into the
          rapidly growing agritourism market. With our step-by-step guidance and expert insights, you&#x27;ll learn
          everything you need to know to turn your land into a thriving agritourism destination.
        </p>
      </div>
      <div id="w-node-cefdd1a6-0d4e-c0a0-ff07-885959de280f-b7eace21" class="banner-content-inner">
        <curbnturf-agritourism-sign-up-form></curbnturf-agritourism-sign-up-form>
      </div>
    </div>
  </div>
</div>
<div class="content-container">
  <div style="padding-top: 56.27659574468085%" class="w-video w-embed">
    <iframe
      class="embedly-embed"
      src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Ffast.wistia.net%2Fembed%2Fiframe%2Ffslsyuxxvz&display_name=Wistia%2C+Inc.&url=https%3A%2F%2Fcurbnturf.wistia.com%2Fmedias%2Ffslsyuxxvz&image=https%3A%2F%2Fembed-ssl.wistia.com%2Fdeliveries%2F3d59d1651b84640bf76d85c3a097cd5f0df25ddf.jpg%3Fimage_crop_resized%3D960x540&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=wistia"
      scrolling="no"
      allowfullscreen=""
      title="Challenge Course Intro"
    ></iframe>
  </div>
</div>
<section class="advantages-outer">
  <div data-w-id="1906f7c5-107f-4392-5b33-9ee17bccb28e" class="content-container">
    <div class="treatment-category-wrap">
      <div id="w-node-_28ccf4ae-c90d-0a41-8d0c-dd7bcce29356-b7eace21" class="challenge-header">
        <h2>There&#x27;s something for everybody.</h2>
      </div>
      <div
        style="
          -webkit-transform: translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          -moz-transform: translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          -ms-transform: translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          transform: translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
        "
        class="treatment-category-single-item"
      >
        <div class="treatment-content">
          <h4 class="category-title">Level up your business!</h4>
          <p class="paragraph-large">
            Whether you&#x27;re starting an agritourism business from scratch or already have one, our course can help
            you take your venture to the next level.
          </p>
        </div>
        <a
          (click)="signUpModal()"
          data-w-id="1906f7c5-107f-4392-5b33-9ee17bccb296"
          style="width: 42px; height: 42px"
          class="button-orange w-inline-block"
        >
          <div
            style="
              width: 0px;
              height: 0px;
              -webkit-transform: translate3d(-180px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
              -moz-transform: translate3d(-180px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
              -ms-transform: translate3d(-180px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
              transform: translate3d(-180px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            "
            class="button-text"
          >
            Get started
          </div>
          <img src="/img/arrow-white.svg" alt="" class="button-arrow" />
        </a>
      </div>
      <div
        style="
          -webkit-transform: translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          -moz-transform: translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          -ms-transform: translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          transform: translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
        "
        class="treatment-category-single-item"
      >
        <div class="treatment-content">
          <h4 class="category-title">Your best plan for success.</h4>
          <p class="paragraph-large">
            Learn how to choose your agritourism niche and create a business plan that capitalizes on this growing
            trend.
          </p>
        </div>
        <a
          (click)="signUpModal()"
          data-w-id="1906f7c5-107f-4392-5b33-9ee17bccb2a3"
          style="width: 42px; height: 42px"
          class="button-orange w-inline-block"
        >
          <div
            style="
              width: 0px;
              height: 0px;
              -webkit-transform: translate3d(-180px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
              -moz-transform: translate3d(-180px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
              -ms-transform: translate3d(-180px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
              transform: translate3d(-180px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            "
            class="button-text"
          >
            Get started
          </div>
          <img src="/img/arrow-white.svg" alt="" class="button-arrow" />
        </a>
      </div>
      <div
        style="
          -webkit-transform: translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          -moz-transform: translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          -ms-transform: translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          transform: translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
        "
        class="treatment-category-single-item"
      >
        <div class="treatment-content">
          <h4 class="category-title">Top secret tips.</h4>
          <p class="paragraph-large">
            Get insider tips on providing your guests with a memorable experience that keeps them coming back for more.
          </p>
        </div>
        <a
          (click)="signUpModal()"
          data-w-id="42366d71-5975-5a52-08e2-aa9561af803e"
          style="width: 42px; height: 42px"
          class="button-orange w-inline-block"
        >
          <div
            style="
              width: 0px;
              height: 0px;
              -webkit-transform: translate3d(-180px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
              -moz-transform: translate3d(-180px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
              -ms-transform: translate3d(-180px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
              transform: translate3d(-180px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            "
            class="button-text"
          >
            Get Started
          </div>
          <img src="/img/arrow-white.svg" alt="" class="button-arrow" />
        </a>
      </div>
      <div
        style="
          -webkit-transform: translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          -moz-transform: translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          -ms-transform: translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          transform: translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
        "
        class="treatment-category-single-item"
      >
        <div class="treatment-content">
          <h4 class="category-title">Unlock industry secrets!</h4>
          <p class="paragraph-large">
            Discover the secret sauce to turning your property into a money-making machine and monetizing it in multiple
            ways.
          </p>
        </div>
        <a
          (click)="signUpModal()"
          data-w-id="1906f7c5-107f-4392-5b33-9ee17bccb2bd"
          style="width: 42px; height: 42px"
          class="button-orange w-inline-block"
        >
          <div
            style="
              width: 0px;
              height: 0px;
              -webkit-transform: translate3d(-180px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
              -moz-transform: translate3d(-180px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
              -ms-transform: translate3d(-180px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
              transform: translate3d(-180px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            "
            class="button-text"
          >
            Get Started
          </div>
          <img src="/img/arrow-white.svg" alt="" class="button-arrow" />
        </a>
      </div>
    </div>
  </div>
</section>
<section data-w-id="d535c307-3242-9066-f1ef-eb61c12f61ce" class="scroll-section-wrapper">
  <div class="sticky">
    <div class="tabs">
      <div class="challenge-course-days-area _1">
        <div data-w-id="d535c307-3242-9066-f1ef-eb61c12f6214" class="challenge-course-inner">
          <div class="section-title-wrap grid-item section-padding">
            <h3 class="section-title color-white">What&#x27;s inside the challenge course!</h3>
            <div id="w-node-d535c307-3242-9066-f1ef-eb61c12f621a-b7eace21" class="view-all-button-wrap">
              <div
                (click)="signUpModal()"
                id="w-node-d535c307-3242-9066-f1ef-eb61c12f621b-b7eace21"
                class="view-all-button clickable"
              >
                Sign up today
              </div>
              <img src="/img/arrow-white-02.svg" loading="lazy" alt="" class="view-all-icon-image" />
            </div>
          </div>
          <div class="section-content-wrap">
            <div
              style="
                -webkit-transform: translate3d(0, 0, 0) scale3d(0.5, 0.5, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                -moz-transform: translate3d(0, 0, 0) scale3d(0.5, 0.5, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                -ms-transform: translate3d(0, 0, 0) scale3d(0.5, 0.5, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                transform: translate3d(0, 0, 0) scale3d(0.5, 0.5, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
              "
              class="single-item-card"
            >
              <div class="item-content">
                <div class="benefits-content">
                  <div class="discount-tag">
                    <div class="discount-title">FREE</div>
                  </div>
                  <h4 class="test-title">
                    <strong>Day 1 - Choose Your Niche:</strong> Identify your agritourism niche
                  </h4>
                </div>
                <div class="price-tag">
                  <div class="seal-price">$149</div>
                  <div class="regular-price">$199</div>
                </div>
              </div>
              <img
                src="/img/Day1_Thumbnail-Optimized.webp"
                loading="lazy"
                sizes="(max-width: 991px) 100vw, 28vw"
                srcset="
                  /img/Day1_Thumbnail-Optimized-p-500.jpg  500w,
                  /img/Day1_Thumbnail-Optimized-p-800.jpg  800w,
                  /img/Day1_Thumbnail-Optimized.webp      1280w
                "
                alt=""
                class="treatment-icon"
              />
            </div>
            <div class="single-item-card style-01">
              <div class="item-content">
                <div class="benefits-content">
                  <div class="discount-tag style-01">
                    <div class="discount-title">FREE</div>
                  </div>
                  <h4 class="test-title"><strong>Day 2 - Community Connection: </strong>Engage your local community</h4>
                </div>
                <div class="price-tag">
                  <div class="seal-price">$149</div>
                  <div class="regular-price">$199</div>
                </div>
              </div>
              <img
                src="/img/Day-2-Thumbnail-Optimized.webp"
                loading="lazy"
                sizes="(max-width: 991px) 100vw, 28vw"
                srcset="
                  /img/Day-2-Thumbnail-Optimized-p-500.jpg  500w,
                  /img/Day-2-Thumbnail-Optimized-p-800.jpg  800w,
                  /img/Day-2-Thumbnail-Optimized.webp      1280w
                "
                alt=""
                class="treatment-icon"
              />
            </div>
            <div class="single-item-card style-02">
              <div class="item-content">
                <div class="benefits-content">
                  <div class="discount-tag style-02">
                    <div class="discount-title">FREE</div>
                  </div>
                  <h4 class="test-title"><strong>Day 3 - Local Love: </strong>Learn about programs available to you</h4>
                </div>
                <div class="price-tag">
                  <div class="seal-price">$149</div>
                  <div class="regular-price">$199</div>
                </div>
              </div>
              <img
                src="/img/Day-3_Thumbnail-Optimized.webp"
                loading="lazy"
                sizes="(max-width: 991px) 100vw, 28vw"
                srcset="
                  /img/Day-3_Thumbnail-Optimized-p-500.jpg  500w,
                  /img/Day-3_Thumbnail-Optimized-p-800.jpg  800w,
                  /img/Day-3_Thumbnail-Optimized.webp      1280w
                "
                alt=""
                class="treatment-icon"
              />
            </div>
          </div>
        </div>
      </div>
      <div data-w-id="27c6efdd-b1a0-31af-03f1-449a1d75568c" class="challenge-course-inner">
        <div class="section-title-wrap grid-item section-padding">
          <h3 class="section-title color-white">What&#x27;s inside the challenge course!</h3>
          <div id="w-node-dea18ab6-53d3-35f6-dca0-b15394dca205-b7eace21" class="view-all-button-wrap">
            <div
              (click)="signUpModal()"
              id="w-node-dea18ab6-53d3-35f6-dca0-b15394dca206-b7eace21"
              class="view-all-button clickable"
            >
              Sign up today
            </div>
            <img src="/img/arrow-white-02.svg" loading="lazy" alt="" class="view-all-icon-image" />
          </div>
        </div>
        <div class="lab-best-deals-wrap">
          <div class="single-item-card style-02">
            <div class="item-content">
              <div class="benefits-content">
                <div class="discount-tag">
                  <div class="discount-title">FREE</div>
                </div>
                <h4 class="test-title">
                  <strong>Day 4 - Maximize Your Money: </strong>Learn how upsell and cross-sell
                </h4>
              </div>
              <div class="price-tag">
                <div class="seal-price">$149</div>
                <div class="regular-price style-02">$199</div>
              </div>
            </div>
            <img
              src="/img/Day-4_Thumbnail-Optimized.webp"
              loading="lazy"
              sizes="(max-width: 991px) 100vw, 24vw"
              srcset="
                /img/Day-4_Thumbnail-Optimized-p-500.jpg  500w,
                /img/Day-4_Thumbnail-Optimized-p-800.jpg  800w,
                /img/Day-4_Thumbnail-Optimized.webp      1280w
              "
              alt=""
              class="treatment-icon"
            />
          </div>
          <div class="single-item-card style-02">
            <div class="item-content">
              <div class="benefits-content">
                <div class="discount-tag style-01">
                  <div class="discount-title">FREE</div>
                </div>
                <h4 class="test-title"><strong>Day 5 - Host With The Most: </strong>The secret sauce to earn money</h4>
              </div>
              <div class="price-tag">
                <div class="seal-price">$299</div>
                <div class="regular-price style-02">$349</div>
              </div>
            </div>
            <img
              src="/img/Day-5_Thumbnail-Optimized.webp"
              loading="lazy"
              sizes="(max-width: 991px) 100vw, 25vw"
              srcset="
                /img/Day-5_Thumbnail-Optimized-p-500.jpg  500w,
                /img/Day-5_Thumbnail-Optimized-p-800.jpg  800w,
                /img/Day-5_Thumbnail-Optimized.webp      1280w
              "
              alt=""
              class="treatment-icon"
            />
          </div>
          <div class="single-item-card style-02">
            <div class="item-content">
              <div class="benefits-content">
                <div class="discount-tag style-02">
                  <div class="discount-title">FREE</div>
                </div>
                <h4 class="test-title">
                  <strong>Sign Up Today:</strong> Reserve my spot for the five-day agritourism challenge.
                </h4>
              </div>
              <div class="price-tag">
                <div class="seal-price">$845</div>
                <div class="regular-price style-02">$1145</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="content-container day-benefits">
  <div class="card-content-outer nandor">
    <div class="section-title-wrap grid-item section-padding">
      <h3 class="section-title color-white">What&#x27;s inside the challenge course!</h3>
      <div id="w-node-_3235f0c3-3ab4-1db8-587e-fe9f64d7737e-b7eace21" class="view-all-button-wrap">
        <div
          (click)="signUpModal()"
          id="w-node-_3235f0c3-3ab4-1db8-587e-fe9f64d7737f-b7eace21"
          class="view-all-button clickable"
        >
          Sign up today
        </div>
        <img src="/img/arrow-white-02.svg" loading="lazy" alt="" class="view-all-icon-image" />
      </div>
    </div>
    <div class="banner-content-grid">
      <div id="w-node-_02d810b8-b6dc-d75a-a5e2-163fedffa06a-b7eace21" class="lab-best-deals-wrap">
        <div class="single-item-card">
          <div class="item-content">
            <div class="benefits-content">
              <div class="discount-tag">
                <div class="discount-title">FREE</div>
              </div>
              <h4 class="test-title"><strong>Day 1 - Choose Your Niche:</strong> Identify your agritourism niche</h4>
            </div>
            <div class="price-tag">
              <div class="seal-price">$149</div>
              <div class="regular-price">$199</div>
            </div>
          </div>
          <img
            src="/img/Day1_Thumbnail-Optimized.webp"
            loading="lazy"
            sizes="(max-width: 479px) 100vw, (max-width: 991px) 88vw, 100vw"
            srcset="
              /img/Day1_Thumbnail-Optimized-p-500.jpg  500w,
              /img/Day1_Thumbnail-Optimized-p-800.jpg  800w,
              /img/Day1_Thumbnail-Optimized.webp      1280w
            "
            alt=""
            class="treatment-icon small"
          />
        </div>
        <div class="single-item-card style-01">
          <div class="item-content">
            <div class="benefits-content">
              <div class="discount-tag style-01">
                <div class="discount-title">FREE</div>
              </div>
              <h4 class="test-title"><strong>Day 2 - Community Connection: </strong>Engage your local community</h4>
            </div>
            <div class="price-tag">
              <div class="seal-price">$149</div>
              <div class="regular-price">$199</div>
            </div>
          </div>
          <img
            src="/img/Day-2-Thumbnail-Optimized.webp"
            loading="lazy"
            sizes="(max-width: 479px) 100vw, (max-width: 991px) 88vw, 100vw"
            srcset="
              /img/Day-2-Thumbnail-Optimized-p-500.jpg  500w,
              /img/Day-2-Thumbnail-Optimized-p-800.jpg  800w,
              /img/Day-2-Thumbnail-Optimized.webp      1280w
            "
            alt=""
            class="treatment-icon small"
          />
        </div>
        <div class="single-item-card style-02">
          <div class="item-content">
            <div class="benefits-content">
              <div class="discount-tag style-02">
                <div class="discount-title">FREE</div>
              </div>
              <h4 class="test-title"><strong>Day 3 - Local Love: </strong>Learn about programs available to you</h4>
            </div>
            <div class="price-tag">
              <div class="seal-price">$149</div>
              <div class="regular-price">$199</div>
            </div>
          </div>
          <img
            src="/img/Day-3_Thumbnail-Optimized.webp"
            loading="lazy"
            sizes="(max-width: 479px) 100vw, (max-width: 991px) 88vw, 100vw"
            srcset="
              /img/Day-3_Thumbnail-Optimized-p-500.jpg  500w,
              /img/Day-3_Thumbnail-Optimized-p-800.jpg  800w,
              /img/Day-3_Thumbnail-Optimized.webp      1280w
            "
            alt=""
            class="treatment-icon small"
          />
        </div>
        <div class="single-item-card style-02">
          <div class="item-content">
            <div class="benefits-content">
              <div class="discount-tag">
                <div class="discount-title">FREE</div>
              </div>
              <h4 class="test-title"><strong>Day 4 - Maximize Your Money: </strong>Learn how upsell and cross-sell</h4>
            </div>
            <div class="price-tag">
              <div class="seal-price">$149</div>
              <div class="regular-price">$199</div>
            </div>
          </div>
          <img
            src="/img/Day-4_Thumbnail-Optimized.webp"
            loading="lazy"
            sizes="(max-width: 479px) 100vw, (max-width: 991px) 88vw, 100vw"
            srcset="
              /img/Day-4_Thumbnail-Optimized-p-500.jpg  500w,
              /img/Day-4_Thumbnail-Optimized-p-800.jpg  800w,
              /img/Day-4_Thumbnail-Optimized.webp      1280w
            "
            alt=""
            class="treatment-icon small"
          />
        </div>
        <div class="single-item-card style-02">
          <div class="item-content">
            <div class="benefits-content">
              <div class="discount-tag style-01">
                <div class="discount-title">FREE</div>
              </div>
              <h4 class="test-title"><strong>Day 5 - Host With The Most: </strong>The secret sauce to earn money</h4>
            </div>
            <div class="price-tag">
              <div class="seal-price">$299</div>
              <div class="regular-price">$349</div>
            </div>
          </div>
          <img
            src="/img/Day-5_Thumbnail-Optimized.webp"
            loading="lazy"
            sizes="(max-width: 479px) 100vw, (max-width: 991px) 88vw, 100vw"
            srcset="
              /img/Day-5_Thumbnail-Optimized-p-500.jpg  500w,
              /img/Day-5_Thumbnail-Optimized-p-800.jpg  800w,
              /img/Day-5_Thumbnail-Optimized.webp      1280w
            "
            alt=""
            class="treatment-icon small"
          />
        </div>
        <div class="single-item-card style-02">
          <div class="item-content">
            <div class="benefits-content">
              <div class="discount-tag style-02">
                <div class="discount-title">FREE</div>
              </div>
              <h4 class="test-title">
                <strong>Sign Up Today:</strong> Reserve my spot for the five-day agritourism challenge.
              </h4>
            </div>
            <div class="price-tag">
              <div class="seal-price">$845</div>
              <div class="regular-price">$1145</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="content-container">
  <div class="card-content-outer">
    <div class="banner-content-grid">
      <div id="w-node-_84cef816-9ba2-7c32-53da-64bb7c111d5a-b7eace21" class="lab-best-deals-wrap">
        <div class="single-item-card style-02">
          <div class="item-content">
            <div class="benefits-content">
              <div class="discount-tag">
                <div class="discount-title">DAILY VIDEOS</div>
              </div>
              <p>
                Each day&#x27;s video will provide you with actionable insights and strategies to take your agritourism
                business to the next level. Whether you&#x27;re just starting out or looking to grow an existing
                business, this challenge course is designed to help you succeed.
              </p>
              <ul role="list">
                <li>Each video covers a unique aspect of building and operating a thriving agritourism venture.</li>
                <li>
                  Each video covers a specific topic, such as choosing your agritourism niche, creating a marketing
                  plan, developing an unforgettable guest experience, and more.
                </li>
                <li>
                  The videos are accessible online, so you can watch them at your own pace and on your own schedule.
                </li>
              </ul>
            </div>
            <div class="benefits-image-outer">
              <img
                src="/img/Day1_Thumbnail.webp"
                loading="lazy"
                width="307"
                sizes="(max-width: 479px) 100vw, (max-width: 991px) 307px, 7vw"
                alt=""
                srcset="
                  /img/Day1_Thumbnail-p-500.png   500w,
                  /img/Day1_Thumbnail-p-800.png   800w,
                  /img/Day1_Thumbnail-p-1080.png 1080w,
                  /img/Day1_Thumbnail.webp       1280w
                "
                class="benefits-image"
              />
            </div>
          </div>
        </div>
        <div class="single-item-card style-02">
          <div class="item-content">
            <div class="benefits-content">
              <div class="discount-tag style-01">
                <div class="discount-title">DAILY WORKSHEETS</div>
              </div>
              <p>
                Each daily video, you&#x27;ll receive a helpful PDF guide/worksheet to support your learning and
                implementation. These guides are designed to provide you with the knowledge, resources, and tools you
                need to create a successful agritourism business. The information in the PDFs includes:
              </p>
              <ul role="list">
                <li>In-depth examples of successful agritourism businesses</li>
                <li>Step-by-step instructions to complete daily assignments and exercises</li>
                <li>Resources to help with marketing, sales, and guest experience.</li>
                <li>Insider tips and tricks from the CurbNTurf team and industry experts.</li>
              </ul>
              <p>
                With these worksheets, you&#x27;ll have everything you need to turn your agritourism dream into a
                profitable reality or take it to the next level.
              </p>
            </div>
            <div class="benefits-image-outer">
              <img
                src="/img/AdobeStock_348550726_Orange.png"
                loading="lazy"
                width="103"
                sizes="(max-width: 479px) 100vw, (max-width: 991px) 103px, 7vw"
                alt=""
                srcset="
                  /img/AdobeStock_348550726_Orange-p-500.png  500w,
                  /img/AdobeStock_348550726_Orange-p-800.png  800w,
                  /img/AdobeStock_348550726_Orange.png       5855w
                "
                class="benefits-image"
              />
            </div>
          </div>
        </div>
        <div class="single-item-card style-02">
          <div class="item-content">
            <div class="benefits-content">
              <div class="discount-tag style-02">
                <div class="discount-title">DAY 5 - SECRET SAUCE</div>
              </div>
              <p>
                Each day&#x27;s video will provide you with actionable insights and strategies to take your agritourism
                business to the next level. Whether you&#x27;re just starting out or looking to grow an existing
                business, this challenge course is designed to help you succeed.
              </p>
              <ul role="list">
                <li>Promote your tours, experiences, lessons/classes, and products</li>
                <li>Increase your bookings, while maintaining control of your business</li>
                <li>Get access to a wealth of resources, tools, and insights to help you succeed.</li>
                <li>
                  You&#x27;ll have access to a powerful network of travelers looking for unique, off-the-beaten-path
                  experiences just like yours.
                </li>
              </ul>
            </div>
            <div class="benefits-image-outer">
              <img
                src="/img/Top-Secret_Orange.png"
                loading="lazy"
                width="307"
                sizes="(max-width: 479px) 100vw, (max-width: 991px) 307px, 7vw"
                alt=""
                srcset="
                  /img/Top-Secret_Orange-p-500.png    500w,
                  /img/Top-Secret_Orange-p-3200.png  3200w,
                  /img/Top-Secret_Orange.png        20834w
                "
                class="benefits-image"
              />
            </div>
          </div>
        </div>
        <a (click)="signUpModal()" id="w-node-_84cef816-9ba2-7c32-53da-64bb7c111d9e-b7eace21" class="button w-button">
          Get started for free
        </a>
      </div>
    </div>
  </div>
</div>
<section class="expertise-section">
  <div class="content-container">
    <div class="expertise-inner-wrap">
      <div data-w-id="6dbccc89-0f0b-0acc-9838-6cece548fa03" style="opacity: 0" class="podcast-single-item">
        <div class="tag-wrap">
          <div class="expertise-tag">
            <div class="dot"></div>
            <div class="expertise-subtitle">Podcast</div>
          </div>
        </div>
        <h3 class="expertise-title">Listen to our podcast, the Recurring Plot.</h3>
        <p class="paragraph-expertise">
          Each week we&#x27;ll invite experts on our show to walk you through the steps of creating another income
          stream from the asset you already have: Your property.
        </p>
        <div class="video-content-wrap">
          <a href="#" class="video-lightbox w-inline-block w-lightbox">
            <div class="video-button">
              <img src="/img/video_1video.png" loading="lazy" alt="" class="icon-video" />
            </div>
            <script type="application/json" class="w-json">
              {
                "items": [
                  {
                    "url": "https://youtube.com/watch?v=EgHqIcKM9Vk",
                    "originalUrl": "https://youtube.com/watch?v=EgHqIcKM9Vk",
                    "width": 940,
                    "height": 528,
                    "thumbnailUrl": "https://i.ytimg.com/vi/EgHqIcKM9Vk/hqdefault.jpg",
                    "html": "<iframe class=\"embedly-embed\" src=\"//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FEgHqIcKM9Vk%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DEgHqIcKM9Vk&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FEgHqIcKM9Vk%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube\" width=\"940\" height=\"528\" scrolling=\"no\" title=\"YouTube embed\" frameborder=\"0\" allow=\"autoplay; fullscreen\" allowfullscreen=\"true\"></iframe>",
                    "type": "video"
                  }
                ],
                "group": ""
              }
            </script>
          </a>
        </div>
        <img src="/img/RP_Logo_Final_Trinidad.svg" loading="lazy" width="144" alt="" class="recurringplot-logo" />
      </div>
      <div id="w-node-_6dbccc89-0f0b-0acc-9838-6cece548fa14-b7eace21" class="grid-two">
        <div
          id="w-node-_6dbccc89-0f0b-0acc-9838-6cece548fa15-b7eace21"
          data-w-id="6dbccc89-0f0b-0acc-9838-6cece548fa15"
          style="opacity: 0"
          class="live-event-single-item"
        >
          <div class="tag-wrap">
            <div class="events-tag">
              <div class="dot"></div>
              <div class="expertise-subtitle">Download CurbNTurf App</div>
            </div>
          </div>
          <img
            src="/img/Phone-TripTych.png"
            loading="lazy"
            width="188"
            id="w-node-_6dbccc89-0f0b-0acc-9838-6cece548fa20-b7eace21"
            alt=""
            srcset="
              /img/Phone-TripTych-p-500.png   500w,
              /img/Phone-TripTych-p-800.png   800w,
              /img/Phone-TripTych-p-1080.png 1080w,
              /img/Phone-TripTych-p-1600.png 1600w,
              /img/Phone-TripTych.png        1920w
            "
            sizes="(max-width: 479px) 0.84375px, (max-width: 991px) 18vw, 100vw"
            class="count-image"
          />
          <div class="stream-content-wrap">
            <h4 class="expertise-title app">
              Download the App to help you earn money from your property by hosting guests!
            </h4>
            <div class="stream-time">5.0 Rating in the App Store</div>
          </div>
        </div>
        <div id="w-node-_14b9b613-21d8-1c28-6f23-ba4617873f5b-b7eace21" class="single-download">
          <div class="div-block-99">
            <h3 class="h3-riftsoft">FREE TO DOWNLOAD, FREE TO USE.</h3>
            <div class="app-download-outer app">
              <a id="w-node-eb5d5bab-aa2d-2c98-59d0-5801f4f9e873-b7eace21" href="#" class="app-button w-inline-block"
                ><img
                  src="/img/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
                  loading="lazy"
                  alt=""
                  class="apple-store"
              /></a>
              <a id="w-node-eb5d5bab-aa2d-2c98-59d0-5801f4f9e875-b7eace21" href="#" class="app-button w-inline-block"
                ><img src="/img/google-play-badge.svg" loading="lazy" alt="" class="google-play"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="content-container">
    <div class="instructor-inner">
      <div id="w-node-_892ece21-d0d3-1bc2-9156-97ac1e4a71cb-b7eace21">
        <div id="w-node-_7f11571c-431c-8be5-640d-6fd258635682-b7eace21" class="profile-dustin">
          <img
            src="/img/Profile_Clipped.webp"
            loading="lazy"
            width="644"
            sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, (max-width: 991px) 644px, 49vw"
            alt=""
            srcset="
              /img/Profile_Clipped-p-500.png   500w,
              /img/Profile_Clipped-p-800.png   800w,
              /img/Profile_Clipped-p-1080.png 1080w,
              /img/Profile_Clipped.webp       1200w
            "
            class="image-profile"
          />
        </div>
      </div>
      <div data-w-id="892ece21-d0d3-1bc2-9156-97ac1e4a71ba" style="opacity: 0" class="background-black">
        <div class="tag-wrap">
          <div class="discount-tag style-02">
            <div class="discount-title">DUSTIN REED</div>
          </div>
        </div>
        <h3 class="expertise-title"><strong>Meet Your Instructor</strong></h3>
        <p class="paragraph-large meet">
          Are you ready to become the ultimate agritourism pro? Join our five-day challenge course and unlock the
          secrets to hosting unforgettable experiences that will keep your guests coming back for more. From choosing
          your perfect niche to creating a killer marketing plan, we&#x27;ve got you covered.
        </p>
        <p class="paragraph-expertise meet">
          Howdy! I&#x27;m Dustin, your instructor for the 5-Day Agritourism Challenge Course. With years of experience
          leading national campaigns for a major grocery chain and now as Creative Director of CurbNTurf, and the host
          of the Recurring Plot podcast. I&#x27;m thrilled to guide you through creating your own successful agritourism
          business. Coming from a fourth-generation dairy farming family, I know the land and the opportunities it can
          bring. Get ready to learn everything from choosing your niche to maximizing your profits. Let&#x27;s milk this
          for all it&#x27;s worth!
        </p>
        <a (click)="signUpModal()" class="button w-button">Get started for free</a>
      </div>
      <div id="w-node-_2828db51-ca33-07a0-c15d-1ad38175f307-b7eace21" class="background-black">
        <h2>Here&#x27;s a sneak peak of some of the benefits the challenge course!</h2>
        <div class="line-div"></div>
        <p class="paragraph-large">
          Are you ready to become the ultimate agritourism pro? Join our five-day challenge course and unlock the
          secrets to hosting unforgettable experiences that will keep your guests coming back for more. From choosing
          your perfect niche to creating a killer marketing plan, we&#x27;ve got you covered.
        </p>
        <p class="paragraph-large">
          But that&#x27;s not all - when you sign up, you&#x27;ll get access to expert tips and tricks from our team of
          agritourism gurus, as well as handy PDFs to guide you through each step. And let&#x27;s not forget about the
          daily videos, which are sure to leave you laughing and inspired.
        </p>
        <p class="paragraph-large">
          But wait, there&#x27;s more! On day five, we&#x27;ll unveil the secret sauce - a platform that will
          revolutionize the way you host your property. Trust us, this is something you don&#x27;t want to miss.
        </p>
        <p class="paragraph-large">
          So what are you waiting for? Sign up now and become the envy of all your agritourism-loving friends. Plus, who
          knows - you might just discover a newfound passion for goat yoga. (Yes, that&#x27;s a real thing.)
        </p>
      </div>
    </div>
  </div>
</section>
<div class="content-container">
  <div style="padding-top: 56.27659574468085%" class="w-video w-embed">
    <iframe
      class="embedly-embed"
      src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Ffast.wistia.net%2Fembed%2Fiframe%2Fim32prlw0q&display_name=Wistia%2C+Inc.&url=https%3A%2F%2Fcurbnturf.wistia.com%2Fmedias%2Fim32prlw0q&image=https%3A%2F%2Fembed-ssl.wistia.com%2Fdeliveries%2F8e65f584389b2437bb855d9f5c641e081f6f3999.jpg%3Fimage_crop_resized%3D960x540&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=wistia"
      scrolling="no"
      allowfullscreen=""
      title="Your Property is Their Park"
    ></iframe>
  </div>
</div>
<section class="advantages-outer">
  <div data-w-id="c0f830a3-67b9-aab2-8fc8-bc01194972d5" class="content-container">
    <div class="treatment-category-wrap">
      <div id="w-node-c0f830a3-67b9-aab2-8fc8-bc01194972d7-b7eace21" class="challenge-header">
        <h2>Turn Your Land Into a Cash Cow with CurbNTurf!</h2>
      </div>
      <div
        id="w-node-c0f830a3-67b9-aab2-8fc8-bc01194972db-b7eace21"
        style="
          -webkit-transform: translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          -moz-transform: translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          -ms-transform: translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
          transform: translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
        "
        class="treatment-category-single-item"
      >
        <div class="treatment-content">
          <p class="paragraph-large">
            Hey you! Yes, you with the land! Are you tired of feeling like your property is a moo-t point? Then look no
            further than CurbNTurf, the ultimate platform for hosting your property to adventurous travelers. We&#x27;re
            not just any old hosting platform, we&#x27;re the pasture-perfect choice for agribusinesses and property
            owners alike!
          </p>
          <p class="paragraph-large">
            At CurbNTurf, we&#x27;re udderly obsessed with helping you milk your property&#x27;s potential for all
            it&#x27;s worth. Our user-friendly interface and expert guidance will help you graze through the world of
            agritourism with ease. We don&#x27;t just provide tools for success, we also provide the cow-cuddling
            customer service you deserve.
          </p>
          <p class="paragraph-large">
            Get insider tips on providing your guests with a memorable experience that keeps them coming back for more.
          </p>
        </div>
        <a
          (click)="signUpModal()"
          data-w-id="c0f830a3-67b9-aab2-8fc8-bc01194972e1"
          style="width: 42px; height: 42px"
          class="button-orange w-inline-block"
        >
          <div
            style="
              width: 0px;
              height: 0px;
              -webkit-transform: translate3d(-180px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
              -moz-transform: translate3d(-180px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
              -ms-transform: translate3d(-180px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
              transform: translate3d(-180px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
            "
            class="button-text"
          >
            Get started
          </div>
          <img src="/img/arrow-white.svg" alt="" class="button-arrow" /> </a
        ><img src="/img/icon-cashcow.svg" loading="lazy" width="149" alt="" class="treatment-icon" />
      </div>
      <div id="w-node-e0a3acbf-d14d-2f4a-42c1-9d44aa19f88d-b7eace21">
        <img
          src="/img/AdobeStock_233666630-optimized.jpg"
          loading="lazy"
          id="w-node-_608a62e8-0379-ca3e-437d-247e30ea389b-b7eace21"
          width="766"
          alt=""
          srcset="
            /img/AdobeStock_233666630-optimized-p-500.jpg  500w,
            /img/AdobeStock_233666630-optimized-p-800.jpg  800w,
            /img/AdobeStock_233666630-optimized.jpg       1080w
          "
          sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 47vw, 22vw"
          class="image-12"
        />
      </div>
      <div id="w-node-_50350f6b-d7c0-0945-4e2d-d89531d43e4f-b7eace21">
        <img
          src="/img/AdobeStock_159154543-optimized.webp"
          loading="lazy"
          sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 47vw, 22vw"
          srcset="/img/AdobeStock_159154543-optimized-p-500.jpg 500w, /img/AdobeStock_159154543-optimized.webp 1080w"
          alt=""
          class="image-11"
        />
      </div>
    </div>
  </div>
</section>
<div class="content-container">
  <div class="card-content-outer">
    <div class="banner-content-grid _3up">
      <h2 id="w-node-d9500dc1-6b5f-97e9-429a-498f72c1e44a-b7eace21">Easy as...</h2>
      <div id="w-node-_0cc1f661-a18f-917c-bf0d-e776ba1f66da-b7eace21">
        <div><img src="/img/Number1.svg" loading="lazy" alt="" class="number1" /></div>
        <div class="challenge-emphasis">Click below to join the Free 5-Day Agritourism Challenge Course.</div>
      </div>
      <div id="w-node-_567c0138-8292-3c8f-53aa-e2986c8f6f1f-b7eace21">
        <div><img src="/img/Number2.svg" loading="lazy" alt="" class="number2" /></div>
        <div class="challenge-emphasis">
          You&#x27;ll be directed to the membership area where you can sign up and log in.
        </div>
      </div>
      <div id="w-node-_42760dc3-d4ab-6886-bd18-2553b3cb2c44-b7eace21">
        <div><img src="/img/Number3.svg" loading="lazy" alt="" class="number3" /></div>
        <div class="challenge-emphasis">
          For the Five Days, you&#x27;ll have daily access to new trainings and receive reminder emails to keep you
          motivated and on track.
        </div>
      </div>
      <div id="w-node-_943b0bea-b0cc-e89d-8fb1-1f7e5966ea00-b7eace21" class="challenge-center">
        <a
          (click)="signUpModal()"
          id="w-node-_8eed813b-f4cd-4aba-1e21-43609175c0c5-b7eace21"
          data-w-id="8eed813b-f4cd-4aba-1e21-43609175c0c5"
          class="button w-button"
        >
          Get started for free
        </a>
      </div>
    </div>
  </div>
</div>
<div class="content-container">
  <div class="card-content-outer nandor">
    <div class="banner-content-grid">
      <h2 id="w-node-_38eaf590-295d-8f09-ac83-65064adcd671-b7eace21" class="h2-head center">
        Ready to harvest some serious profits? Our challenge course will show you how!
      </h2>
      <div id="w-node-d0496077-8d25-bb69-aa45-13505686bcf1-b7eace21" class="line-div"></div>
      <div id="w-node-_1cd8d5cb-1607-8164-2852-8405f7be6ba3-b7eace21">
        <img
          src="/img/AdobeStock_233666630-optimized.jpg"
          loading="lazy"
          width="620"
          sizes="(max-width: 479px) 100vw, (max-width: 767px) 81vw, (max-width: 991px) 620px, 42vw"
          alt=""
          srcset="
            /img/AdobeStock_233666630-optimized-p-500.jpg  500w,
            /img/AdobeStock_233666630-optimized-p-800.jpg  800w,
            /img/AdobeStock_233666630-optimized.jpg       1080w
          "
        />
      </div>
      <div id="w-node-_38eaf590-295d-8f09-ac83-65064adcd678-b7eace21" class="banner-content-inner">
        <curbnturf-agritourism-sign-up-form></curbnturf-agritourism-sign-up-form>
        <p id="w-node-_1eca7322-cd8a-a900-13a1-b1538da7960a-b7eace21" class="challenge-emphasis">
          Don&#x27;t be a chicken, reserve your spot now and start raking in the dough!
        </p>
      </div>
    </div>
  </div>

  <curbnturf-agritourism-footer></curbnturf-agritourism-footer>
</div>
