import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AgritourismService } from '../../../services/agritourism.service';

@Component({
  selector: 'curbnturf-agritourism-day-two',
  templateUrl: './day-two.component.html',
  styleUrls: ['./day-two.component.scss'],
})
export class AgritourismDayTwoComponent {
  constructor(private agritourismService: AgritourismService, private router: Router) {}

  complete() {
    this.agritourismService.complete(2).subscribe(() => {
      this.router.navigate(['', 'course', 'day-3']);
    });
  }
}
