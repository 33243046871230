import { isPlatformBrowser } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BASE_API_URL } from '@curbnturf/entities';
import { DateTime } from 'luxon';
import { asyncScheduler, Observable, Subject } from 'rxjs';
import { catchError, filter, first, skipWhile, switchMap, throttleTime } from 'rxjs/operators';
import { AgritourismLocalStorageService } from '../services/local-storage.service';
import { AgritourismAuthService } from './auth.service';

@Injectable()
export class AgritourismJwtInterceptor implements HttpInterceptor {
  debouncedLogout: () => void;
  renewSubject: Subject<string>;
  renew$: Observable<string>;

  constructor(
    private authService: AgritourismAuthService,
    private localStorageService: AgritourismLocalStorageService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    this.renewSubject = new Subject();
    this.renew$ = this.renewSubject.asObservable();

    this.renew$
      .pipe(
        filter((command) => command === 'renew'),
        throttleTime(3000, asyncScheduler, { leading: true, trailing: false }),
      )
      .subscribe(() =>
        this.authService
          .renew()
          .pipe(
            catchError((err) => {
              /*
               * err of HttpErrorResponse means that we just aren't connected to internet
               * so we don't want to logout until they have a chance to retry connecting
               */
              if (
                err.name !== 'HttpErrorResponse' ||
                err.error?.message === 'Invalid Refresh Token' ||
                err.error?.message === 'Refresh Token has expired'
              ) {
                this.authService.logout().subscribe();
              }
              throw new Error(err);
            }),
          )
          .subscribe((result) => this.renewSubject.next(result)),
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (isPlatformBrowser(this.platformId) && request.url.includes(BASE_API_URL)) {
      // add authorization header with jwt token if available

      const user = this.localStorageService.user;
      if (
        user &&
        (!user.authExpires ||
          // if the auth is expired or will expire in the next 30 seconds to allow for slow internet
          user.authExpires < DateTime.now().toMillis() + 30000)
      ) {
        setTimeout(() => this.renewSubject.next('renew'));

        return this.renew$.pipe(
          skipWhile((command) => command !== 'success' && command !== 'deferred'),
          first(),
          switchMap(() => this.intercept(request, next)),
        );
      } else if (user?.authIdToken) {
        return next.handle(
          request.clone({
            setHeaders: {
              Authorization: user.authIdToken,
            },
          }),
        );
      } else {
        return next.handle(request);
      }
    }

    return next.handle(request);
  }
}
