import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AgritourismService } from '../../../services/agritourism.service';

@Component({
  selector: 'curbnturf-agritourism-day-four',
  templateUrl: './day-four.component.html',
  styleUrls: ['./day-four.component.scss'],
})
export class AgritourismDayFourComponent {
  constructor(private agritourismService: AgritourismService, private router: Router) {}

  complete() {
    this.agritourismService.complete(4).subscribe(() => {
      this.router.navigate(['', 'course', 'day-5']);
    });
  }
}
