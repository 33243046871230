import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { AgritourismAppComponent } from './app.component';
import { agritourismAppRoutes } from './app.routes';
import { AgritourismAuthGuard } from './auth/auth.guard';
import { AgritourismCognitoService } from './auth/cognito.service';
import { AgritourismJwtInterceptor } from './auth/jwt.interceptor';
import { UnauthenticatedErrorInterceptor } from './auth/unauthenticated-error.interceptor';
import { AgritourismAuthLayoutComponent } from './components/auth/auth-layout/auth-layout.component';
import { AgritourismAuthModalComponent } from './components/auth/auth-modal/auth-modal.component';
import { AgritourismFooterComponent } from './components/footer/footer.component';
import { AgritourismHeaderComponent } from './components/header/header.component';
import { AgritourismLayoutComponent } from './components/layout/layout.component';
import { AgritourismSignUpFormComponent } from './components/sign-up-form/sign-up-form.component';
import { AgritourismAuthRedirectComponent } from './pages/auth/auth-redirect/auth-redirect.component';
import { AgritourismLoginComponent } from './pages/auth/login/login.component';
import { AgritourismLogoutComponent } from './pages/auth/logout/logout.component';
import { AgritourismCourseStatusPromptComponent } from './pages/courses/course-status-prompt/course-status-prompt.component';
import { AgritourismCoursesComponent } from './pages/courses/courses.component';
import { AgritourismDayFiveComponent } from './pages/courses/day-five/day-five.component';
import { AgritourismDayFourComponent } from './pages/courses/day-four/day-four.component';
import { AgritourismDayOneComponent } from './pages/courses/day-one/day-one.component';
import { AgritourismDayThreeComponent } from './pages/courses/day-three/day-three.component';
import { AgritourismDayTwoComponent } from './pages/courses/day-two/day-two.component';
import { AgritourismHomeComponent } from './pages/home/home.component';
import { AgritourismUnsubscribeComponent } from './pages/unsubscribe/unsubscribe.component';

@NgModule({
  declarations: [
    AgritourismAuthLayoutComponent,
    AgritourismAuthModalComponent,
    AgritourismAuthRedirectComponent,
    AgritourismFooterComponent,
    AgritourismLoginComponent,
    AgritourismLogoutComponent,
    AgritourismUnsubscribeComponent,
    AgritourismAppComponent,
    AgritourismCoursesComponent,
    AgritourismCourseStatusPromptComponent,
    AgritourismDayOneComponent,
    AgritourismDayTwoComponent,
    AgritourismDayThreeComponent,
    AgritourismDayFourComponent,
    AgritourismDayFiveComponent,
    AgritourismHeaderComponent,
    AgritourismHomeComponent,
    AgritourismLayoutComponent,
    AgritourismSignUpFormComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    ReactiveFormsModule,
    RouterModule.forRoot(agritourismAppRoutes, { initialNavigation: 'enabledBlocking', onSameUrlNavigation: 'reload' }),
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AgritourismJwtInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthenticatedErrorInterceptor,
      multi: true,
    },
    AgritourismAuthGuard,
    AgritourismCognitoService,
  ],
  bootstrap: [AgritourismAppComponent],
})
export class AppModule {}
