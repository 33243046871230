import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BASE_API_URL, IUser } from '@curbnturf/entities';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { AgritourismLocalStorageService } from '../services/local-storage.service';
import { AgritourismCognitoService } from './cognito.service';

const AUTH_API_URL = BASE_API_URL + 'auth';
const USER_API_URL = BASE_API_URL + 'user';
const AG_API_URL = BASE_API_URL + 'agritourism';

@Injectable({
  providedIn: 'root',
})
export class AgritourismAuthService {
  constructor(
    private cognitoService: AgritourismCognitoService,
    private http: HttpClient,
    private localStorageService: AgritourismLocalStorageService,
    private router: Router,
  ) {}

  /**
   * Login and load the user
   */
  public login(email: string, password: string): Observable<IUser | undefined> {
    return from(this.cognitoService.authenticateUser(email?.trim(), password?.trim())).pipe(
      // migration code
      catchError(async (err) => {
        if (err.code === 'UserNotConfirmedException') {
          await this.router.navigate(['', 'auth', 'verifying']);
          throw new Error('User must verify their email before logging in.');
        }

        if (err.code === 'NotAuthorizedException' || err.code === 'UserNotFoundException') {
          throw new Error('Please re-enter your email and password and try again');
        }

        if (err.code === 'PasswordResetRequiredException') {
          throw new Error(
            'Your password needs to be reset. Click the "Forgot password" link and follow the included steps to proceed.',
          );
        }

        throw new Error(err.code);
      }),
      switchMap((user: IUser) => {
        return this.storeLogin(user);
      }),
    );
  }

  /**
   * Stores the login tokens and gets the user from the server which is then returned
   *
   * @param user - This user should have the authAccessToken, authIdToken, authRenewToken, and authExpires set.
   * @returns an observable with a user loaded from the server without the tokens.
   */
  public storeLogin(user: IUser): Observable<IUser> {
    // login successful if there's a jwt token in the response
    if (user?.authIdToken && user.authRenewToken) {
      // save the auth tokens to the auth store
      this.localStorageService.user = user;

      // get the current user using the auth keys so they are fully populated
      return this.getCurrentUser().pipe(
        switchMap((existingUser) => {
          if (existingUser && existingUser.id) {
            // update the auth store to include update user information specifically id and role
            this.localStorageService.user = {
              ...user,
              ...existingUser,
            };

            return of(existingUser);
          }

          throw new Error('Unable to find user on server');
        }),
      );
    }

    throw new Error('Invalid login user');
  }

  public logout(): Observable<Record<string, unknown>> {
    // remove user and anything else from local storage to log user out
    this.localStorageService.clear();

    this.router.navigateByUrl('/');

    return of({});
  }

  public register(user: IUser, password: string): Observable<IUser> {
    if (!user.email || !password) {
      throw new Error('Missing username or password');
    }

    return from(this.cognitoService.signUp(user.email.trim(), password.trim())).pipe(
      mergeMap(() => {
        user.email = user.email?.toLocaleLowerCase();

        // Create a Sync ID so we can sync this object later.
        user.syncId = uuidv4();

        return this.http.post(`${USER_API_URL}`, user);
      }),
    );
  }

  // we can get the current user using /agritourism as long as we have the tokens stored
  public getCurrentUser(): Observable<IUser> {
    return this.http.get<IUser>(`${AG_API_URL}`);
  }

  public renew(): Observable<string> {
    const user = this.localStorageService.user;
    if (user?.authRenewToken && user?.authIdToken) {
      return this.cognitoService.renew(user.authRenewToken, user.authIdToken).pipe(
        switchMap((userWithTokens) => {
          // once we renew the tokens we will replace the existing tokens and re-pull the user
          return this.storeLogin({
            id: user.id,
            ...userWithTokens,
          }).pipe(map(() => 'success'));
        }),
      );
    }

    throw new Error('Unable to renew token');
  }

  public confirmEmail(confirmationCode: string, username: string): Observable<boolean> {
    return this.http.get<boolean>(`${AUTH_API_URL}?confirmationCode=${confirmationCode}&username=${username}`);
  }
}
