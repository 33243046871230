<div class="content-container">
  <div class="card-content-outer nandor">
    <div class="overflow-hidden">
      <h1 class="banner-title">Welcome to the Five Day Challenge Course!</h1>
      <div class="challenge-emphasis">Jump into a module below:</div>
      <div class="challenge-course-grid">
        <div id="w-node-a90b2ea4-b2cc-e6d0-2640-b2fb30bd1ed0-d3dddc7a" class="challenge-course-card">
          <a [routerLink]="['', 'course', 'day-1']" class="challenge-course-link w-inline-block">
            <img
              src="/img/AGTOUR-CC_-DAY-1-Optimized.webp"
              loading="lazy"
              sizes="(max-width: 479px) 85vw, (max-width: 767px) 88vw, (max-width: 991px) 42vw, 27vw"
              srcset="
                /img/AGTOUR-CC_-DAY-1-Optimized-p-500.jpg   500w,
                /img/AGTOUR-CC_-DAY-1-Optimized-p-800.jpg   800w,
                /img/AGTOUR-CC_-DAY-1-Optimized-p-1080.jpg 1080w,
                /img/AGTOUR-CC_-DAY-1-Optimized.webp       1280w
              "
              alt="Day 1"
            />
          </a>
          <div class="challenge-course-bot">
            <agritourism-course-course-status-prompt [completed]="user?.agritourismCourse?.day1Status === 'completed'">
            </agritourism-course-course-status-prompt>
            <div class="challenge-course-title-wrap">
              <a [routerLink]="['', 'course', 'day-1']" class="day-link">Day 1 - Choose Your Niche</a>
              <img
                *ngIf="user?.agritourismCourse?.day1Status === 'completed'"
                src="/img/correct.svg"
                loading="lazy"
                alt=""
                class="img-complete"
              />
            </div>
          </div>
        </div>
        <div id="w-node-_7fd94e1b-4a03-a66a-9d15-bddb5e1afc75-d3dddc7a" class="challenge-course-card">
          <a [routerLink]="['', 'course', 'day-2']" class="challenge-course-link w-inline-block">
            <img
              src="/img/AGTOUR-CC_-DAY-2-Optimized.webp"
              loading="lazy"
              sizes="(max-width: 479px) 85vw, (max-width: 767px) 88vw, (max-width: 991px) 42vw, 27vw"
              srcset="
                /img/AGTOUR-CC_-DAY-2-Optimized-p-500.jpg   500w,
                /img/AGTOUR-CC_-DAY-2-Optimized-p-800.jpg   800w,
                /img/AGTOUR-CC_-DAY-2-Optimized-p-1080.jpg 1080w,
                /img/AGTOUR-CC_-DAY-2-Optimized.webp       1280w
              "
              alt="Day 2"
            />
          </a>
          <div class="challenge-course-bot">
            <agritourism-course-course-status-prompt [completed]="user?.agritourismCourse?.day2Status === 'completed'">
            </agritourism-course-course-status-prompt>
            <div class="challenge-course-title-wrap">
              <a [routerLink]="['', 'course', 'day-2']" class="day-link">Day 2 - Community Connection / Outreach</a>
              <img
                *ngIf="user?.agritourismCourse?.day2Status === 'completed'"
                src="/img/correct.svg"
                loading="lazy"
                alt=""
                class="img-complete"
              />
            </div>
          </div>
        </div>
        <div id="w-node-fce3660d-556e-5169-bc50-843942ecd7bb-d3dddc7a" class="challenge-course-card">
          <a [routerLink]="['', 'course', 'day-3']" class="challenge-course-link w-inline-block">
            <img
              src="/img/AGTOUR-CC_-DAY-3-Optimized.webp"
              loading="lazy"
              sizes="(max-width: 479px) 85vw, (max-width: 767px) 88vw, (max-width: 991px) 42vw, 27vw"
              srcset="
                /img/AGTOUR-CC_-DAY-3-Optimized-p-500.jpg   500w,
                /img/AGTOUR-CC_-DAY-3-Optimized-p-800.jpg   800w,
                /img/AGTOUR-CC_-DAY-3-Optimized-p-1080.jpg 1080w,
                /img/AGTOUR-CC_-DAY-3-Optimized.webp       1280w
              "
              alt="Day 3"
            />
          </a>
          <div class="challenge-course-bot">
            <agritourism-course-course-status-prompt [completed]="user?.agritourismCourse?.day3Status === 'completed'">
            </agritourism-course-course-status-prompt>
            <div class="challenge-course-title-wrap">
              <a [routerLink]="['', 'course', 'day-3']" class="day-link">Day 3 - Programs available to you</a>
              <img
                *ngIf="user?.agritourismCourse?.day3Status === 'completed'"
                src="/img/correct.svg"
                loading="lazy"
                alt=""
                class="img-complete"
              />
            </div>
          </div>
        </div>
        <div id="w-node-_990b297d-72d7-93c0-85fd-77247607517c-d3dddc7a" class="challenge-course-card">
          <a [routerLink]="['', 'course', 'day-4']" class="challenge-course-link w-inline-block">
            <img
              src="/img/AGTOUR-CC_-DAY-4-Optimized.webp"
              loading="lazy"
              sizes="(max-width: 479px) 85vw, (max-width: 767px) 88vw, (max-width: 991px) 42vw, 27vw"
              srcset="
                /img/AGTOUR-CC_-DAY-4-Optimized-p-500.jpg   500w,
                /img/AGTOUR-CC_-DAY-4-Optimized-p-800.jpg   800w,
                /img/AGTOUR-CC_-DAY-4-Optimized-p-1080.jpg 1080w,
                /img/AGTOUR-CC_-DAY-4-Optimized.webp       1280w
              "
              alt="Day 4"
            />
          </a>
          <div class="challenge-course-bot">
            <agritourism-course-course-status-prompt [completed]="user?.agritourismCourse?.day4Status === 'completed'">
            </agritourism-course-course-status-prompt>
            <div class="challenge-course-title-wrap">
              <a [routerLink]="['', 'course', 'day-4']" class="day-link">Day 4 - Upsell &amp; Cross-sell</a>
              <img
                *ngIf="user?.agritourismCourse?.day4Status === 'completed'"
                src="/img/correct.svg"
                loading="lazy"
                alt=""
                class="img-complete"
              />
            </div>
          </div>
        </div>
        <div id="w-node-_49339d60-02ab-d9a3-a3b4-fc93a90d422a-d3dddc7a" class="challenge-course-card">
          <a [routerLink]="['', 'course', 'day-5']" class="challenge-course-link w-inline-block">
            <img
              src="/img/AGTOUR-CC_DAY-5-Optimized.webp"
              loading="lazy"
              sizes="(max-width: 479px) 85vw, (max-width: 767px) 88vw, (max-width: 991px) 42vw, 27vw"
              srcset="
                /img/AGTOUR-CC_DAY-5-Optimized-p-500.jpg   500w,
                /img/AGTOUR-CC_DAY-5-Optimized-p-800.jpg   800w,
                /img/AGTOUR-CC_DAY-5-Optimized-p-1080.jpg 1080w,
                /img/AGTOUR-CC_DAY-5-Optimized.webp       1280w
              "
              alt="Day 5"
            />
          </a>
          <div class="challenge-course-bot">
            <agritourism-course-course-status-prompt [completed]="user?.agritourismCourse?.day5Status === 'completed'">
            </agritourism-course-course-status-prompt>
            <div class="challenge-course-title-wrap">
              <a [routerLink]="['', 'course', 'day-5']" class="day-link">Day 5 - Host guests</a>
              <img
                *ngIf="user?.agritourismCourse?.day5Status === 'completed'"
                src="/img/correct.svg"
                loading="lazy"
                alt=""
                class="img-complete"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="content-container">
  <div class="instructor-inner">
    <div id="w-node-_1e348b43-39cc-4977-92bb-81b395c00744-d3dddc7a" class="grid-two">
      <div id="w-node-_1e348b43-39cc-4977-92bb-81b395c00745-d3dddc7a" class="profile-dustin">
        <img
          src="/img/Profile_Clipped.webp"
          loading="lazy"
          width="715"
          sizes="(max-width: 479px) 44vw, (max-width: 767px) 46vw, (max-width: 991px) 47vw, 23vw"
          alt="Dustin Reed"
          srcset="
            /img/Profile_Clipped-p-500.png   500w,
            /img/Profile_Clipped-p-800.png   800w,
            /img/Profile_Clipped-p-1080.png 1080w,
            /img/Profile_Clipped.webp       1200w
          "
          class="image-profile"
        />
      </div>
    </div>
    <div data-w-id="1e348b43-39cc-4977-92bb-81b395c00747" style="opacity: 0" class="background-black">
      <div class="tag-wrap">
        <div class="discount-tag style-02">
          <div class="discount-title">DUSTIN REED</div>
        </div>
      </div>
      <h3 class="expertise-title"><strong>Meet Your Instructor</strong></h3>
      <p class="paragraph-large meet">
        Howdy! I&#x27;m Dustin, your instructor for the 5-Day Agritourism Challenge Course. With years of experience
        leading national campaigns for a major grocery chain and now as Creative Director of CurbNTurf, and the host of
        the Recurring Plot podcast. I&#x27;m thrilled to guide you through creating your own successful agritourism
        business. Coming from a fourth-generation dairy farming family, I know the land and the opportunities it can
        bring. Get ready to learn everything from choosing your niche to maximizing your profits. Let&#x27;s milk this
        for all it&#x27;s worth!
      </p>
      <p class="paragraph-expertise meet">
        Howdy! I&#x27;m Dustin, your instructor for the 5-Day Agritourism Challenge Course. With years of experience
        leading national campaigns for a major grocery chain and now as Creative Director of CurbNTurf, and the host of
        the Recurring Plot podcast. I&#x27;m thrilled to guide you through creating your own successful agritourism
        business. Coming from a fourth-generation dairy farming family, I know the land and the opportunities it can
        bring. Get ready to learn everything from choosing your niche to maximizing your profits. Let&#x27;s milk this
        for all it&#x27;s worth!
      </p>
    </div>
    <div
      id="w-node-_5aeb88a9-b879-427c-e392-976378d74a9e-d3dddc7a"
      data-w-id="5aeb88a9-b879-427c-e392-976378d74a9e"
      style="opacity: 0"
      class="podcast-single-item"
    >
      <div class="tag-wrap">
        <div class="expertise-tag">
          <div class="dot"></div>
          <div class="expertise-subtitle">Podcast</div>
        </div>
      </div>
      <h3 class="expertise-title">Listen to our podcast, the Recurring Plot.</h3>
      <p class="paragraph-expertise">
        Each week we&#x27;ll invite experts on our show to walk you through the steps of creating another income stream
        from the asset you already have: Your property.
      </p>
      <div class="video-content-wrap">
        <a href="https://recurringplot.com/" class="video-lightbox w-inline-block w-lightbox">
          <div class="video-button">
            <img src="/img/icon-play.svg" loading="lazy" alt="" class="icon-video" />
          </div>
        </a>
      </div>
      <img src="/img/RP_Logo_Final_Trinidad.svg" loading="lazy" width="144" alt="" class="recurringplot-logo" />
    </div>
  </div>
</div>
