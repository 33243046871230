<div class="content-container">
  <div class="card-content-outer nandor">
    <div class="overflow-hidden">
      <h1 class="banner-title">Day 4: Upsell and Cross Sell Within your Agritourism Business.</h1>
      <div class="header-paragraph">Cross-selling</div>
      <p>
        Cross-selling is a sales technique that involves encouraging customers to make additional purchases related to
        the product they have already bought. It often involves suggesting complementary items or services that could
        enhance their experience with the original purchase.
      </p>
      <div class="header-paragraph">Upselling</div>
      <p>
        Upselling is a sales technique that involves suggesting and encouraging customers to upgrade their purchases to
        gain more value. This can involve offering an upgraded version of the same product or service, or adding
        additional features and benefits.
      </p>
      <p>
        Today&#x27;s video explains how to use both techniques in your agritourism business. We discuss various products
        and services that you can offer your customers, as well as ways to use upselling and cross-selling strategies
        appropriately to increase your revenue.
      </p>
      <div class="challenge-emphasis">Jump into DAY 4 COURSE below:<br /></div>
      <div class="challenge-course-vid">
        <div class="w-video w-embed">
          <vg-player>
            <vg-overlay-play></vg-overlay-play>
            <vg-buffering></vg-buffering>
            <vg-controls>
              <vg-play-pause></vg-play-pause>
              <vg-playback-button></vg-playback-button>
              <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
              <vg-scrub-bar>
                <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
              </vg-scrub-bar>
              <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
              <vg-mute></vg-mute>
              <vg-volume></vg-volume>
              <vg-fullscreen></vg-fullscreen>
            </vg-controls>

            <video [vgMedia]="$any(media)" #media id="singleVideo" preload="auto" crossorigin>
              <source src="/videos/acc-day-4.webm" type='video/webm; codecs="vp8, vorbis"' />
              <source src="/videos/acc-day-4.mp4" type='video/mp4; codecs="avc1.4d002a"' />
            </video>
          </vg-player>
        </div>
      </div>
      <div class="course-control-wrapper">
        <a href="../documents/Day-4---Upselling-and-Cross-Selling-SM.pdf" class="attachment-pdf w-inline-block">
          <div class="copy-image">Click Here To Download Day 4 Handout (pdf)</div>
        </a>
        <a (click)="complete()" class="button-primary w-inline-block">
          <div class="button-content">
            <div class="button-color">Mark as Complete</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
