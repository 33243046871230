import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { stringFromError } from '@curbnturf/form-helpers/src/lib/helpers/text';
import { Subscription } from 'rxjs';
import { AgritourismAuthService } from '../../auth/auth.service';
import { AgritourismService } from '../../services/agritourism.service';
import { AgritourismAlertService } from '../../services/alert.service';

@Component({
  selector: 'curbnturf-agritourism-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss'],
})
export class AgritourismSignUpFormComponent {
  @Input() modal: boolean = false;

  formSummitted: boolean = false;
  formError: boolean = false;

  registerForm: FormGroup<{
    email: FormControl<string | null>;
    firstName: FormControl<string | null>;
    lastName: FormControl<string | null>;
    password: FormControl<string | null>;
    source: FormControl<string | null>;
  }> = this.fb.group({
    email: this.fb.control<string | null>('', [Validators.required, Validators.email]),
    firstName: this.fb.control<string | null>('', [Validators.required]),
    lastName: this.fb.control<string | null>('', [Validators.required]),
    password: this.fb.control<string | null>('', [Validators.required]),
    source: this.fb.control<string | null>(''),
  });

  subscriptions: Subscription[] = [];

  constructor(
    private agritourismService: AgritourismService,
    private alertService: AgritourismAlertService,
    private authService: AgritourismAuthService,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.registerForm.markAllAsTouched();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public async register() {
    if (this.registerForm?.valid) {
      this.formError = false;
      const value = this.registerForm.value;

      if (!value?.email || !value.password) {
        this.formError = true;
      } else {
        this.authService
          .register(
            {
              email: value?.email ? value.email : '',
              firstName: value?.firstName ? value.firstName : '',
              lastName: value?.lastName ? value.lastName : '',
              source: value?.source ? value.source : '',
            },
            value.password,
          )
          .subscribe({
            next: (user) => {
              if (user?.id) {
                this.alertService.alert(
                  'success',
                  'User has successfully been created and an email has been sent to verify your email address.',
                  'User Signed Up',
                );

                this.agritourismService.enroll(user.id).subscribe();
                this.formSummitted = true;
              }
            },
            error: (err) => {
              this.alertService.alert('error', stringFromError(err), 'Error Signing Up User');
            },
          });
      }
    } else {
      this.formError = true;
    }
  }
}
