<div class="nandor-background">
  <div class="wrapper">
    <div class="auth-logo-wrapper">
      <h2 class="heading-2 white">Welcome to</h2>
      <div class="_6-px-spacer"></div>
      <img src="/img/CNT_Logo_V_White.svg" alt="CurbNTurf Logo" />
    </div>
    <div class="spacer"></div>
    <div class="auth-wrapper">
      <div class="auth-inner-wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
