import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HelperFunctions, IUser } from '@curbnturf/entities';
import { AgritourismLocalStorageService } from '../../services/local-storage.service';

@Component({
  selector: 'curbnturf-agritourism-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class AgritourismHeaderComponent {
  user?: IUser;
  position: number = 0;

  getPhotoUrl = HelperFunctions.getPhotoUrlSquareTiny;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private localStorageService: AgritourismLocalStorageService,
    private router: Router,
  ) {}

  ngOnInit() {
    const url = this.document.location.pathname;
    this.position = parseInt(url?.substring(url?.length - 1), 10);
    this.user = this.localStorageService.user;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = this.document.location.pathname;
        this.position = parseInt(url?.substring(url?.length - 1), 10);
      }
    });
  }
}
