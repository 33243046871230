export enum FlowStepType {
  RoleSelector = 'RoleSelector',
  // SiteTypeSelector = 'SiteTypeSelector',
  Source = 'Source',
  ProfilePhone = 'ProfilePhone',
  SiteTerrain = 'SiteTerrain',
  SiteAmenities = 'SiteAmenities',
  SiteActivities = 'SiteActivities',
  SiteIsolation = 'SiteIsolation',
  SiteBoondockLand = 'SiteBoondockLand',
  SiteRVs = 'SiteRVs',
  SiteDetails = 'SiteDetails',
  SitePrice = 'SitePrice',
  SiteBooking = 'SiteBooking',
  SiteRules = 'SiteRules',
  SiteLocation = 'SiteLocation',
  SiteDescription = 'SiteDescription',
  UserProfilePhoto = 'UserProfilePhoto',
  UserGarage = 'UserGarage',
  UserRv = 'UserRv',
  UserRvImage = 'UserRvImage',
  UserRvSize = 'UserRvSize',
  UserRoadConditions = 'UserRoadConditions',
  UserTent = 'UserTent',
  UserComplete = 'UserComplete',
  SitePhotos = 'SitePhotos',
  SitePublish = 'SitePublish',
}
