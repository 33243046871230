import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_API_URL } from '@curbnturf/entities';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AgritourismLocalStorageService } from '../services/local-storage.service';
import { AgritourismAuthService } from './auth.service';

@Injectable()
export class UnauthenticatedErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AgritourismAuthService,
    private localStorageService: AgritourismLocalStorageService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (
          err instanceof HttpErrorResponse &&
          err.status === 401 &&
          // we only log them out if the 401 is from our server
          err.url?.includes(BASE_API_URL)
        ) {
          if (this.localStorageService.user) {
            this.authService.logout().subscribe();
          }

          throw new Error('You have been logged out by the server');
        }

        throw new Error(err);
      }),
    );
  }
}
