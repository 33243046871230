<div class="login-modal-component">
  <div data-w-id="81367d56-82b4-c982-2dd4-49767f7ec262" class="login-modal-content-wrapper">
    <div class="text-align-center">
      <div class="max-width-large align-center">
        <p class="text-size-medium">
          We&#x27;re thrilled you&#x27;re considering this agritourism course to help you on for your journey on
          improving your agribusiness or start your agribusiness! To gain access to the video and materials, please
          fill out the following information*:
        </p>
        <curbnturf-agritourism-sign-up-form [modal]="true"></curbnturf-agritourism-sign-up-form>
      </div>
    </div>
    <p class="fine-print">*Your login credentials for this website are managed by CurbNTurf.</p>
  </div>
  <a
    fs-scrolldisable-element="enable"
    data-w-id="81367d56-82b4-c982-2dd4-49767f7ec2a7"
    href="#"
    class="login-modal-close-button w-inline-block"
  >
    <div class="icon-embed-x w-embed">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 72 72"
        style="enable-background: new 0 0 72 72"
        xml:space="preserve"
      >
        <style type="text/css">
          .st0 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #fff;
          }
        </style>
        <path
          class="st0"
          d="M43.7,36L70.4,9.3c2.1-2.1,2.1-5.6,0-7.7c-2.1-2.1-5.6-2.1-7.7,0L36,28.3L9.3,1.6c-2.1-2.1-5.6-2.1-7.7,0
c-2.1,2.1-2.1,5.6,0,7.7L28.3,36L1.6,62.7c-2.1,2.1-2.1,5.6,0,7.7c2.1,2.1,5.6,2.1,7.7,0L36,43.7l26.7,26.7c2.1,2.1,5.6,2.1,7.7,0
c2.1-2.1,2.1-5.6,0-7.7L43.7,36z"
        ></path>
      </svg>
    </div>
    <img
      src="https://uploads-ssl.webflow.com/624380709031623bfe4aee60/624380709031623afe4aee7e_icon_close-modal.svg"
      loading="lazy"
      alt=""
      class="show-mobile-landscape"
    />
  </a>
  <div
    fs-scrolldisable-element="enable"
    data-w-id="81367d56-82b4-c982-2dd4-49767f7ec2aa"
    class="login-modal-background-overlay"
  ></div>
</div>
