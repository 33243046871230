import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AgritourismService } from '../../../services/agritourism.service';

@Component({
  selector: 'curbnturf-agritourism-day-three',
  templateUrl: './day-three.component.html',
  styleUrls: ['./day-three.component.scss'],
})
export class AgritourismDayThreeComponent {
  constructor(private agritourismService: AgritourismService, private router: Router) {}

  complete() {
    this.agritourismService.complete(3).subscribe(() => {
      this.router.navigate(['', 'course', 'day-4']);
    });
  }
}
