import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AgritourismAlertService {
  toasts: HTMLIonToastElement[] = [];

  constructor(@Inject(PLATFORM_ID) private platformId: string, public toastController: ToastController) {}

  async alert(level: string, body: any, title: string, html?: boolean, noTimeout?: boolean) {
    let color: string;
    switch (level) {
      case 'error': {
        color = 'danger';
        break;
      }
      case 'warning': {
        color = 'warning';
        break;
      }
      case 'info':
      case 'success':
      default: {
        color = 'success';
        break;
      }
    }

    if (!isPlatformServer(this.platformId)) {
      let message: string = '';
      if (typeof body === 'object') {
        message = body.message;
      } else if (typeof body === 'string') {
        message = body;
      } else {
        message = 'An unknown error has occurred. Please try again';
      }

      const toast = await this.toastController.create({
        header: title,
        message: message,
        color,
        position: 'top',
        duration: noTimeout ? 0 : 10000,
        buttons: [
          {
            icon: 'close',
            role: 'cancel',
          },
        ],
      });
      this.toasts.push(toast);

      await toast.present();

      toast.onDidDismiss().then(() => {
        const index = this.toasts.findIndex((toastEl) => toastEl === toast);
        this.toasts.splice(index, 1);
      });
    }
  }

  closeAll() {
    this.toasts.forEach((toast) => {
      toast.dismiss();
    });
    this.toasts.length = 0;
  }
}
