<div class="_18-px-spacer"></div>
<h2 class="heading-2 white">Log in here.</h2>
<div class="w-form">
  <form [formGroup]="loginForm" (ngSubmit)="login()">
    <label for="login-email" class="sr-only">Email Address</label>
    <input
      id="login-email"
      type="email"
      class="_48px-text-field w-input"
      formControlName="email"
      [email]="true"
      [required]="true"
      placeholder="Email address"
    />
    <label for="login-password" class="sr-only">Password</label>
    <input
      id="login-password"
      type="password"
      class="_48px-text-field w-input"
      formControlName="password"
      [required]="true"
      placeholder="Password"
    />
    <div class="_18-px-spacer"></div>
    <button type="submit" class="cnt-button nandor w-inline-block">
      <div class="cnt-button-copy">Log in</div>
    </button>
    <div class="_12-px-spacer"></div>
    <div class="_18-px-spacer"></div>
    <div class="forgot-password white">
      <strong> Forgot password? </strong>
      Go to <a href="https://www.curbnturf.com/auth/recover" target="_blank" rel="noopener noreferrer">CurbNTurf</a> to
      recover it.
    </div>
  </form>
</div>
<div class="_18-px-spacer"></div>
