import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS } from '@curbnturf/entities';
import { Observable } from 'rxjs';
import { AgritourismLocalStorageService } from '../services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AgritourismAuthGuard {
  constructor(
    private localStorageService: AgritourismLocalStorageService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: string,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      if (isPlatformServer(this.platformId)) {
        return resolve(true);
      }

      const loggedIn = Boolean(this.localStorageService.user);
      if (!loggedIn) {
        this.localStorageService.redirectUrl = this.document.location.pathname;
        this.router.navigate([PATHS.login]);
      } else {
        resolve(loggedIn);
      }
    });
  }

  canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate();
  }
}
