import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AgritourismService } from '../../../services/agritourism.service';
import { AgritourismAlertService } from '../../../services/alert.service';

@Component({
  selector: 'curbnturf-agritourism-day-five',
  templateUrl: './day-five.component.html',
  styleUrls: ['./day-five.component.scss'],
})
export class AgritourismDayFiveComponent {
  constructor(
    private agritourismService: AgritourismService,
    private alertService: AgritourismAlertService,
    private router: Router,
  ) {}

  complete() {
    this.agritourismService.complete(5).subscribe(() => {
      this.alertService.alert('success', 'You have completed the Agritourism Challenge Course.', 'Congratulations!');
      this.router.navigate(['', 'course']);
    });
  }
}
