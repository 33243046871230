export const environment = {
  production: false,
  staging: true,
  program: 'web',
  baseUrl: 'https://staging.curbnturf.com',
  apiUrl: 'https://api.staging.curbnturf.com',
  poiApiUrl: 'https://poi.staging.curbnturf.com',
  searchApiUrl: 'https://search.staging.curbnturf.com/v1',
  imageUrl: 'https://images.staging.curbnturf.com',
  mapOldAppCode: 'LKVngL6HJzQjfGIcsfjo9w',
  mapOldAppId: '78EtQKL0GXmuIHrx6FQj',
  mapApiKey: 'FaafKQ_DD5OGht5xLum18bhdWvoW7Fh4g9HJ1eN_3po',
  vapidPublic: 'BJ10wNQS3giBrRXBt-E2dECZ6boVgaU8z0tymA4yVvjKjnAx9Tq2wEudYQS8lo1kxfjheCCnE6icuoNOE4ZXYcg',
  gaCode: 'UA-139775540-1',
  ga4Code: 'G-Y4D07ETPST',
  sentryDsn: '',

  // AWS Cognito
  awsRegion: 'us-west-2',
  cognitoUserPoolId: 'us-west-2_eP2KLUT7w',
  cognitoClientId: '5edbh0uqb3f7o9b49gmg2q0sfu',
  cognitoDomain: 'staging-curbnturf.auth.us-west-2.amazoncognito.com',
  cognitoCallback: 'https://staging.curbnturf.com/auth/redirect',

  // Unused in the web app
  cognitoCallbackIOS: 'curbnturf://auth/redirect',
  cognitoCallbackApp: 'https://staging.curbnturf.com/auth/redirect',

  // Converge CC Processing
  convergeGetTokenUrl: 'https://1ucnnedf29.execute-api.us-west-2.amazonaws.com/process-token/',

  // Ionic Native Only
  offlineDatabaseName: 'curbnturf-offline-data',
};
