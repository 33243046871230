<div class="content-container">
  <div class="card-content-outer nandor">
    <div class="overflow-hidden">
      <h1 class="banner-title">Day 2: How to Leverage Your Community.</h1>
      <p>Growing your agritourism business will get easier and bigger if you involve your community.</p>
      <p>
        In today&#x27;s video, we will explore 5 ways in which you can network with your local community, including a
        bonus at the end! We&#x27;ll also discuss ways to get creative with marketing and awareness tactics to reach out
        to more potential customers.
      </p>
      <p>
        By networking and collaborating with your community, you can increase your customer base and gain more exposure
        for your business.
      </p>
      <div class="challenge-emphasis">Jump into DAY 2 COURSE below:<br /></div>
      <div class="challenge-course-vid">
        <div class="w-video w-embed">
          <vg-player>
            <vg-overlay-play></vg-overlay-play>
            <vg-buffering></vg-buffering>
            <vg-controls>
              <vg-play-pause></vg-play-pause>
              <vg-playback-button></vg-playback-button>
              <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
              <vg-scrub-bar>
                <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
              </vg-scrub-bar>
              <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
              <vg-mute></vg-mute>
              <vg-volume></vg-volume>
              <vg-fullscreen></vg-fullscreen>
            </vg-controls>

            <video [vgMedia]="$any(media)" #media id="singleVideo" preload="auto" crossorigin>
              <source src="/videos/acc-day-2.webm" type='video/webm; codecs="vp8, vorbis"' />
              <source src="/videos/acc-day-2.mp4" type='video/mp4; codecs="avc1.4d002a"' />
            </video>
          </vg-player>
        </div>
      </div>
      <div class="course-control-wrapper">
        <a href="../documents/Day-2---Leverage-Your-Community-SM.pdf" class="attachment-pdf w-inline-block">
          <div class="copy-image">Click Here To Download Day 2 Handout (pdf)</div>
        </a>
        <a (click)="complete()" class="button-primary w-inline-block">
          <div class="button-content">
            <div class="button-color">Mark as Complete</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
