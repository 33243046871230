<div class="content-container">
  <div class="card-content-outer nandor">
    <div class="overflow-hidden">
      <h1 class="banner-title">Day 3: Take Advantage of Local Programs.</h1>
      <p>
        There are vast resources that are available to you to help you with your agritourism business. Did you know that
        there are government aid and resources that are specifically tailored to help agritourism businesses?
      </p>
      <p>
        In this video, we will discuss the different programs that you can leverage to fund, improve, preserve, and
        market your property. With these resources and assistance, you can more easily bring in more customers while
        also helping to grow your business.
      </p>
      <div class="challenge-emphasis">Jump into DAY 3 COURSE below:<br /></div>
      <div class="challenge-course-vid">
        <div class="w-video w-embed">
          <vg-player>
            <vg-overlay-play></vg-overlay-play>
            <vg-buffering></vg-buffering>
            <vg-controls>
              <vg-play-pause></vg-play-pause>
              <vg-playback-button></vg-playback-button>
              <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
              <vg-scrub-bar>
                <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
              </vg-scrub-bar>
              <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
              <vg-mute></vg-mute>
              <vg-volume></vg-volume>
              <vg-fullscreen></vg-fullscreen>
            </vg-controls>

            <video [vgMedia]="$any(media)" #media id="singleVideo" preload="auto" crossorigin>
              <source src="/videos/acc-day-3.webm" type='video/webm; codecs="vp8, vorbis"' />
              <source src="/videos/acc-day-3.mp4" type='video/mp4; codecs="avc1.4d002a"' />
            </video>
          </vg-player>
        </div>
      </div>
      <div class="course-control-wrapper">
        <a href="../documents/Day-3---Programs-Available-to-You-SM.pdf" class="attachment-pdf w-inline-block">
          <div class="copy-image">Click Here To Download Day 3 Handout (pdf)</div>
        </a>
        <a (click)="complete()" class="button-primary w-inline-block">
          <div class="button-content">
            <div class="button-color">Mark as Complete</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
