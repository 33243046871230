import { inject } from '@angular/core';
import { Route } from '@angular/router';
import { AgritourismAuthGuard } from './auth/auth.guard';
import { AgritourismAuthLayoutComponent } from './components/auth/auth-layout/auth-layout.component';
import { AgritourismLayoutComponent } from './components/layout/layout.component';
import { AgritourismAuthRedirectComponent } from './pages/auth/auth-redirect/auth-redirect.component';
import { AgritourismLoginComponent } from './pages/auth/login/login.component';
import { AgritourismLogoutComponent } from './pages/auth/logout/logout.component';
import { AgritourismCoursesComponent } from './pages/courses/courses.component';
import { AgritourismDayFiveComponent } from './pages/courses/day-five/day-five.component';
import { AgritourismDayFourComponent } from './pages/courses/day-four/day-four.component';
import { AgritourismDayOneComponent } from './pages/courses/day-one/day-one.component';
import { AgritourismDayThreeComponent } from './pages/courses/day-three/day-three.component';
import { AgritourismDayTwoComponent } from './pages/courses/day-two/day-two.component';
import { AgritourismHomeComponent } from './pages/home/home.component';
import { AgritourismUnsubscribeComponent } from './pages/unsubscribe/unsubscribe.component';

export const agritourismAppRoutes: Route[] = [
  {
    path: '',
    component: AgritourismHomeComponent,
  },
  {
    path: 'unsubscribe',
    component: AgritourismLayoutComponent,
    children: [
      {
        path: '',
        component: AgritourismUnsubscribeComponent,
      },
    ],
  },
  {
    path: 'course',
    component: AgritourismLayoutComponent,
    children: [
      {
        path: '',
        canActivate: [() => inject(AgritourismAuthGuard).canActivate()],
        component: AgritourismCoursesComponent,
      },
      {
        path: 'day-1',
        canActivate: [() => inject(AgritourismAuthGuard).canActivate()],
        component: AgritourismDayOneComponent,
      },
      {
        path: 'day-2',
        canActivate: [() => inject(AgritourismAuthGuard).canActivate()],
        component: AgritourismDayTwoComponent,
      },
      {
        path: 'day-3',
        canActivate: [() => inject(AgritourismAuthGuard).canActivate()],
        component: AgritourismDayThreeComponent,
      },
      {
        path: 'day-4',
        canActivate: [() => inject(AgritourismAuthGuard).canActivate()],
        component: AgritourismDayFourComponent,
      },
      {
        path: 'day-5',
        canActivate: [() => inject(AgritourismAuthGuard).canActivate()],
        component: AgritourismDayFiveComponent,
      },
    ],
  },
  {
    path: 'auth',
    component: AgritourismAuthLayoutComponent,
    children: [
      {
        path: 'login',
        component: AgritourismLoginComponent,
        data: {
          title: 'Login',
        },
      },
      {
        path: 'logout',
        component: AgritourismLogoutComponent,
        data: {
          title: 'Logout',
        },
      },
      {
        path: 'redirect',
        component: AgritourismAuthRedirectComponent,
        data: {
          title: 'Login Redirect',
        },
      },
      {
        path: '**',
        redirectTo: 'login',
      },
    ],
  },
  { path: '**', redirectTo: '/' },
];
