<div class="content-container">
  <div class="card-content-outer nandor">
    <div class="overflow-hidden">
      <h1 class="banner-title">Day 1: Choose Your Agritourism Niche.</h1>
      <p>
        Agritourism is a travel experience that combines agriculture and tourism. Agritourism revenue has tripled since
        2002, and farms are embracing it! With the right business plan, you can capitalize on this trend.
      </p>
      <p>
        Today we explore agritourism categories and your niche. These categories include educational, outdoor
        recreation, social activities, direct sales, accommodations, and more. What makes you unique in your agritourism
        business in your area?
      </p>
      <p>
        In this video, we will also share some amazing examples of a few successful agritourism businesses. We will
        provide a list that is chock-full of agritourism ideas so you can pick your favorites and add them to your plan.
        We also discuss ways to provide your guests with a memorable experience and keep them coming back for more!
      </p>
      <div class="challenge-emphasis">Jump into DAY 1 COURSE below:<br /></div>
      <div class="challenge-course-vid">
        <div class="w-video w-embed">
          <vg-player>
            <vg-overlay-play></vg-overlay-play>
            <vg-buffering></vg-buffering>
            <vg-controls>
              <vg-play-pause></vg-play-pause>
              <vg-playback-button></vg-playback-button>
              <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
              <vg-scrub-bar>
                <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
              </vg-scrub-bar>
              <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
              <vg-mute></vg-mute>
              <vg-volume></vg-volume>
              <vg-fullscreen></vg-fullscreen>
            </vg-controls>

            <video [vgMedia]="$any(media)" #media id="singleVideo" preload="auto" crossorigin>
              <source src="/videos/acc-day-1.webm" type='video/webm; codecs="vp8, vorbis"' />
              <source src="/videos/acc-day-1.mp4" type='video/mp4; codecs="avc1.4d002a"' />
            </video>
          </vg-player>
        </div>
      </div>
      <div class="course-control-wrapper">
        <a href="../documents/Day-1---Choosing-Your-Agritourism-Niche-SM.pdf" class="attachment-pdf w-inline-block">
          <div class="copy-image">Click Here To Download Day 1 Handout (pdf)</div>
        </a>
        <a (click)="complete()" class="button-primary w-inline-block">
          <div class="button-content">
            <div class="button-color">Mark as Complete</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
